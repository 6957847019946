import React, { Component } from "react";
import { DialogSection } from "../../common/Dialog";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import Colors from "../../common/theme/Colors";
import { TableBase } from "../../common/Tables";

const DivStyle = styled.div`
  border: 1px solid ${Colors.border};
  background-color: ${Colors.white};

  table {
    margin-top: 15px;
  }
  img {
    max-height: 700px;
    max-width: 700px;
  }

  .section-item {
    margin-left: 20px;
  }

  .section-title {
    color: ${Colors.tertiary};
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.5px;
  }

  > .container-fluid > .actions {
    padding: 15px 0px 10px 0px;
  }

  .PARTIAL {
    color: ${Colors.warning};
  }

  .SUCCESS {
    color: ${Colors.success};
  }

  .FAILURE {
    color: ${Colors.error};
  }

  .PENDING,
  .NA {
    color: ${Colors.tertiary};
  }
`;

class CountriesTab extends Component {
  constructor(props) {
    super(props);
  }

  getCountryDetails = (ipRight) => {
    const countries = ipRight.countries;
    if (countries === null || countries.length < 1) {
      return;
    }
    let countryDetails = [];
    countries.forEach((o) => {
      countryDetails.push(this.getCountryDetail(o));
    });

    return <div>{countryDetails}</div>;
  };

  getCountryDesignations = (classDesignations) => {
    const columns = [
      {
        name: "id",
        style: { width: "100px" },
      },
      {
        name: "text",
        contentFunction: (r) => this.getClassText(r),
      },
    ];

    return [
      <DialogSection key="altered-classes">
        <Col xs={12}>
          <div>
            <b>Altered Classes: </b>
            <br />
          </div>
        </Col>
      </DialogSection>,
      <DialogSection key="table">
        <Col xs={12}>
          <TableBase
            columns={columns}
            rows={classDesignations}
            noTableHeader={true}
          />
        </Col>
      </DialogSection>,
    ];
  };

  getClassText = (designation) => {
    if (designation.text === null || designation.text.trim().length === 0) {
      return "(Refused)";
    }
    return designation.text;
  };

  getCountryDetail = (country) => {
    let body = [];

    body.push([
      <DialogSection
        key={country.contry}
        title={country.country}
        className={country.progress}
      >
        <div>
          <b>Status: </b> {country.status}
          <br />
          <br />
        </div>
      </DialogSection>,
    ]);

    if (country.classDesignations && country.classDesignations.length > 0) {
      body.push(this.getCountryDesignations(country.classDesignations));
    }

    return body;
  };

  render() {
    return <DivStyle>{this.getCountryDetails(this.props.ipRight)}</DivStyle>;
  }
}

export default CountriesTab;
