import React, { Component } from "react";
import { Col, Container, FormControl, FormLabel, Row } from "react-bootstrap";
import WindowedSelect, { WindowedMenuList } from "react-windowed-select";
import AsyncSelect from "react-select/async";
import styled from "styled-components";
import { DeleteButton, MainActionButton } from "./Buttons";
import Colors from "./theme/Colors";
import { InputLabel, BorderColor } from "./theme/Theme";
import { IsSet } from "./Utils";

const StyledSelect = styled(WindowedSelect)`
    &.styled-selector.focused {
	   border-bottom: 1px solid ${Colors.secondary};
	   font-weight: 300;
	   font-size: 14px;

	   svg {
	      color:  ${Colors.secondary};
	   }
	}
	
	&.selector__option--is-selected {
		font-size: 20px;
	}
	
    &.styled-selector {
	    border: none;
        border-bottom: 1px solid ${Colors.primary};
        font-weight: 300;
	    font-size: 14px;
	    margin-bottom: 10px;

	    .selector__indicator-separator {
		   width: 0px;
	    }
	   
        .control-selector {
	       border-width: 0px;
	       heigth: 35px;
	       
        }
        
        .is-focused {
	      outline: none transparent;
          box-shadow: none;
	    }
	    
	    svg {
		   color: ${Colors.primary};
	    }
	    
	    .selector__multi-value {
		    background-color:  ${Colors.secondary};
		    svg {
		       color: ${Colors.white};
	        }
	    }
	    
	    .selector__multi-value__label {
		   color: ${Colors.white}
	    }
	    
	    .selector__multi-value__remove:hover {
		   background-color: ${Colors.secondary};
		   svg {
		      color: ${Colors.secondary};
	       }
	    }
	}
	  
    &.Select--multi{
      .Select-multi-value-wrapper{
        .Select-value{
          background: ${Colors.secondary};
          border: 1px solid ${Colors.secondary};
          color: ${Colors.white};
          font-weight: 300;
          border-radius: 3px;
          
          .Select-value-icon{
            border-right: none;
            padding: 1px 7px 1px;
          }
          .Select-value-label{
            padding: 2px 7px 2px 0px;
          }
        }
      }
    }

    &.controller{
      float: right;
      width: 100px;
    }

    &.is-disabled > .Select-control {
	   cursor: not-allowed;
	   background: ${Colors.disabled};
    }
  }
`;

const StyledAsyncSelect = styled(AsyncSelect)`
     &.styled-selector.focused {
	   border-bottom: 1px solid ${Colors.secondary};
	   font-weight: 300;
	   font-size: 14px;
	   
	   svg {
	      color:  ${Colors.secondary};
	   }
	}
	
	&.selector__option--is-selected {
		font-size: 20px;
	}
	
    &.styled-selector {
	    border: none;
        border-bottom: 1px solid ${Colors.primary};
        font-weight: 300;
	    font-size: 14px;
	    margin-bottom: 10px;
	 
	    .selector__indicator-separator {
		   width: 0px;
	    }
	   
        .control-selector {
	       border-width: 0px;
	       heigth: 35px;
	       
        }
        
        .is-focused {
	      outline: none transparent;
          box-shadow: none;
	    }
	    
	    svg {
		   color: ${Colors.primary};
	    }
	    
	    .selector__multi-value {
		    background-color:  ${Colors.secondary};
		    svg {
		       color: ${Colors.white};
	        }
	    }
	    
	    .selector__multi-value__label {
		   color: ${Colors.white}
	    }
	    
	    .selector__multi-value__remove:hover {
		   background-color: ${Colors.secondary};
		   svg {
		      color: ${Colors.secondary};
	       }
	    }
	}
	  
    &.Select--multi{
      .Select-multi-value-wrapper{
        .Select-value{
          background: ${Colors.secondary};
          border: 1px solid ${Colors.secondary};
          color: ${Colors.white};
          font-weight: 300;
          border-radius: 3px;
          
          .Select-value-icon{
            border-right: none;
            padding: 1px 7px 1px;
          }
          .Select-value-label{
            padding: 2px 7px 2px 0px;
          }
        }
      }
    }

    &.controller{
      float: right;
      width: 100px;
    }

    &.is-disabled > .Select-control {
	   cursor: not-allowed;
	   background: ${Colors.disabled};
    }
  }
`;

const Controls = styled.div`
  text-align: center;
  padding-top: 30px;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 30px;

  button {
    margin-right: 0 !important;
    width: 90%;
  }
`;

const StyledFilterSelector = styled(AsyncSelect)`
  &.styled-filter-selector {
    .control-filter-selector {
      border-radius: 4px;
      border: 1px solid ${BorderColor};
      max-height: 34px;
    }

    .filter-selector__control {
      min-height: 34px;
    }

    .is-focused {
      border-color: rgb(224, 224, 224);
      box-shadow: rgba(0, 0, 0, 0.13) 0px 3px 5px inset;
    }

    .filter-selector__dropdown-indicator {
      padding: 4px;
      color: ${Colors.tertiary};
    }

    .filter-selector__placeholder {
      color: ${Colors.tertiary};
    }

    .filter-selector__clear-indicator {
      color: ${Colors.tertiary};
    }
  }
`;

class Selector extends Component {
  constructor(props) {
    super(props);
    this.state = { selection: props.defaultValue, focused: false };
    this.onChange = this.onChange.bind(this);
    this.changeLabelColor = this.changeLabelColor.bind(this);
    this.changeLabelColorBack = this.changeLabelColorBack.bind(this);
  }

  changeLabelColor() {
    this.setState({
      focused: true,
    });
  }

  changeLabelColorBack() {
    this.setState({
      focused: false,
    });
  }

  getLabel() {
    if (this.state.focused) {
      return (
        <InputLabel style={{ color: Colors.secondary }}>
          {this.props.label}
        </InputLabel>
      );
    } else {
      return <InputLabel>{this.props.label}</InputLabel>;
    }
  }

  isFunction(functionToCheck) {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === "[object Function]"
    );
  }

  isContentArray(array) {
    return Array.isArray(array) && array.length > 0 && IsSet(array[0]);
  }

  render() {
    const { value, onChange, ...passThroughProps } = this.props;

    let className = "styled-selector";

    if (this.state.focused) {
      className = "focused styled-selector";
    }

    let selection = passThroughProps.defaultValue;
    let options = this.props.options;

    if (IsSet(passThroughProps.loadOptions)) {
      options = passThroughProps.loadOptions;
    } else if (IsSet(passThroughProps.options)) {
      options = passThroughProps.options;
    }

    if (!this.isFunction(options)) {
      if (IsSet(passThroughProps.defaultValue)) {
        if (this.isContentArray(passThroughProps.defaultValue)) {
          selection = passThroughProps.defaultValue;
        } else {
          selection = options.filter(
            (v) => v.value == passThroughProps.defaultValue,
          );
        }
        if (selection.length === 0) {
          selection = options.filter(
            (v) => v.value == passThroughProps.defaultValue.value,
          );
        }
      } else if (IsSet(this.state.selection)) {
        if (this.isContentArray(this.state.selection)) {
          selection = this.state.selection;
        } else {
          selection = options.filter((v) => v.value == this.state.selection);
        }

        if (selection.length === 0) {
          selection = options.filter(
            (v) => v.value == this.state.selection.value,
          );
        }
      } else if (IsSet(this.props.value)) {
        if (this.isContentArray(this.props.value)) {
          selection = this.props.value;
        } else {
          selection = options.filter((v) => v.value == this.props.value);
        }

        if (selection.length === 0) {
          selection = options.filter((v) => v.value == this.props.value.value);
        }
      }
    }

    if (this.props.isAsync) {
      return (
        <div>
          {this.getLabel()}
          <StyledAsyncSelect
            {...passThroughProps}
            components={{ MenuList: WindowedMenuList }}
            className={className}
            classNamePrefix="selector"
            classNames={{
              control: (state) =>
                state.isFocused
                  ? "is-focused control-selector "
                  : " control-selector ",
            }}
            styles={{
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected
                  ? state.isFocused
                    ? "#007eff10"
                    : "inherit"
                  : state.isFocused
                    ? "#007eff10"
                    : "inherit",
                fontWeight: state.isSelected ? "700" : "300",
                color: "inherit",
                padding: "0.5rem",
                alignItems: "center",
              }),
              placeholder: (base) => ({
                ...base,
                fontSize: "12px",
                fontWeight: 300,
                fontStyle: "oblique",
              }),
            }}
            menuPosition="fixed"
            onFocus={this.changeLabelColor}
            onBlur={this.changeLabelColorBack}
            focused={this.state.focused}
            cacheOptions
            defaultOptions
            onChange={this.onChange}
            defaultValue={selection}
            options={options}
            noOptionsMessage={(a) =>
              !this.isEmpty(a.inputValue) && a.inputValue.length > 2
                ? "No options found"
                : "Type to search"
            }
            isDisabled={this.props.disabled}
          />
        </div>
      );
    } else {
      return (
        <div>
          {this.getLabel()}
          <StyledSelect
            {...passThroughProps}
            className={className}
            classNamePrefix="selector"
            classNames={{
              control: (state) =>
                state.isFocused
                  ? "is-focused control-selector "
                  : " control-selector ",
            }}
            styles={{
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected
                  ? state.isFocused
                    ? "#007eff10"
                    : "inherit"
                  : state.isFocused
                    ? "#007eff10"
                    : "inherit",
                fontWeight: state.isSelected ? "700" : "300",
                color: "inherit",
                padding: "0.5rem",
                alignItems: "center",
              }),
              placeholder: (base) => ({
                ...base,
                fontSize: "12px",
                fontWeight: 300,
                fontStyle: "oblique",
              }),
            }}
            menuPosition="fixed"
            onFocus={this.changeLabelColor}
            onBlur={this.changeLabelColorBack}
            focused={this.state.focused}
            isDisabled={this.props.disabled}
            defaultValue={selection}
            onChange={this.onChange}
            options={options}
            closeMenuOnSelect={!IsSet(passThroughProps.isMulti)}
            removeSelected={false}
          />
        </div>
      );
    }
  }

  isEmpty(value) {
    return (
      value == null || (typeof value === "string" && value.trim().length === 0)
    );
  }

  onChange(event) {
    this.setState({ selection: event });
    this.props.onChange(event);
  }
}

class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: this.getSelectedOptions(props.options, props.value),
      notSelectedOptions: this.getNotSelectedOptions(
        props.options,
        props.value,
      ),
      toRemove: [],
      toAdd: [],
    };
    this.handleSelectedChange = this.handleSelectedChange.bind(this);
    this.handleNotSelectedChange = this.handleNotSelectedChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleRemoveAll = this.handleRemoveAll.bind(this);
    this.handleAddAll = this.handleAddAll.bind(this);
  }

  getSelectedOptions(options, selected) {
    return options.filter((option) => selected.includes(option.value));
  }

  getNotSelectedOptions(options, selected) {
    return options.filter((option) => !selected.includes(option.value));
  }

  handleSelectedChange(event) {
    const options = event.target.options;

    let selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push({ value: options[i].value, label: options[i].label });
      }
    }

    this.setState({ toRemove: selected });
  }

  handleNotSelectedChange(event) {
    const options = event.target.options;

    let selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push({ value: options[i].value, label: options[i].label });
      }
    }

    this.setState({ toAdd: selected });
  }

  createOptions(items) {
    this.sortOptions(items);
    return items.map((item) => (
      <option key={item.value} value={item.value}>
        {item.label}
      </option>
    ));
  }

  handleRemove() {
    if (!this.state.toRemove.length) {
      return;
    }

    let notSelected = [];
    this.state.notSelectedOptions.forEach((option) => notSelected.push(option));

    let toRemoveIds = [];
    this.state.toRemove.forEach((item) => {
      notSelected.push(item);
      toRemoveIds.push(item.value);
    });

    const selected = this.state.selectedOptions.filter(
      (item) => !toRemoveIds.includes(item.value),
    );

    this.sortOptions(notSelected);
    this.sortOptions(selected);

    this.setState({
      selectedOptions: selected,
      notSelectedOptions: notSelected,
      toRemove: [],
      toAdd: [],
    });

    const selectedIds = selected.map((select) => select.value);

    this.props.onChange(selectedIds);
  }

  handleAdd() {
    if (!this.state.toAdd.length) {
      return;
    }

    let selected = [];
    this.state.selectedOptions.forEach((item) => selected.push(item));

    let toAddIds = [];
    this.state.toAdd.forEach((item) => {
      selected.push(item);
      toAddIds.push(item.value);
    });

    const notSelected = this.state.notSelectedOptions.filter(
      (item) => !toAddIds.includes(item.value),
    );

    this.sortOptions(selected);
    this.sortOptions(notSelected);

    this.setState({
      selectedOptions: selected,
      notSelectedOptions: notSelected,
      toRemove: [],
      toAdd: [],
    });

    const selectedIds = selected.map((select) => select.value);

    this.props.onChange(selectedIds);
  }

  handleRemoveAll() {
    if (!this.state.selectedOptions.length) {
      return;
    }

    let notSelected = [];
    this.state.selectedOptions.forEach((item) => notSelected.push(item));

    this.state.notSelectedOptions.forEach((item) => notSelected.push(item));

    this.sortOptions(notSelected);

    this.setState({
      selectedOptions: [],
      notSelectedOptions: notSelected,
      toRemove: [],
      toAdd: [],
    });

    this.props.onChange([]);
  }

  handleAddAll() {
    if (!this.state.notSelectedOptions.length) {
      return;
    }

    let selected = [];
    this.state.selectedOptions.forEach((item) => selected.push(item));

    this.state.notSelectedOptions.forEach((item) => selected.push(item));

    this.sortOptions(selected);

    this.setState({
      selectedOptions: selected,
      notSelectedOptions: [],
      toRemove: [],
      toAdd: [],
    });

    const selectedIds = selected.map((select) => select.value);

    this.props.onChange(selectedIds);
  }

  sortOptions(options) {
    options.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
    return options;
  }

  render() {
    return (
      <Container>
        <Row>
          <Col sm={4}>
            <FormLabel>{this.props.selectedLabel}</FormLabel>
            <FormControl
              as="select"
              multiple
              size={this.props.size}
              onChange={(select) => this.handleSelectedChange(select)}
              onBlur={(select) => this.handleSelectedChange(select)}
              style={{ minHeight: "311px" }}
            >
              {this.createOptions(this.state.selectedOptions)}
            </FormControl>
          </Col>
          <Col sm={4}>
            <Controls>
              <MainActionButton
                onClick={this.handleAddAll}
                text={"Add all"}
                icon={"plus-square"}
                className={"small"}
              />
              <MainActionButton
                onClick={this.handleAdd}
                text={"Add"}
                icon={"plus"}
                className={"small"}
              />
              <div>&nbsp;</div>
              <DeleteButton
                onClick={this.handleRemove}
                text={"Remove"}
                icon={"minus"}
                className={"small"}
              />
              <DeleteButton
                onClick={this.handleRemoveAll}
                text={"Remove All"}
                icon={"minus-square"}
                className={"small"}
              />
            </Controls>
          </Col>
          <Col sm={4}>
            <FormLabel>{this.props.notSelectedLabel}</FormLabel>
            <FormControl
              as="select"
              size={this.props.size}
              multiple
              onChange={(select) => this.handleNotSelectedChange(select)}
              onBlur={(select) => this.handleNotSelectedChange(select)}
              style={{ minHeight: "311px" }}
            >
              {this.createOptions(this.state.notSelectedOptions)}
            </FormControl>
          </Col>
        </Row>
      </Container>
    );
  }
}

class FilterSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { selection: props.defaultValue, focused: false };
    this.onChange = this.onChange.bind(this);
  }

  isFunction(functionToCheck) {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === "[object Function]"
    );
  }

  isContentArray(array) {
    return Array.isArray(array) && array.length > 0 && IsSet(array[0]);
  }

  render() {
    const { value, onChange, ...passThroughProps } = this.props;

    let className = "styled-filter-selector";

    let selection = passThroughProps.defaultValue;
    let options = this.props.options;

    if (IsSet(passThroughProps.loadOptions)) {
      options = passThroughProps.loadOptions;
    } else if (IsSet(passThroughProps.options)) {
      options = passThroughProps.options;
    }

    if (!this.isFunction(options)) {
      if (IsSet(passThroughProps.defaultValue)) {
        if (this.isContentArray(passThroughProps.defaultValue)) {
          selection = passThroughProps.defaultValue;
        } else {
          selection = options.filter(
            (v) => v.value == passThroughProps.defaultValue,
          );
        }
        if (selection.length === 0) {
          selection = options.filter(
            (v) => v.value == passThroughProps.defaultValue.value,
          );
        }
      } else if (IsSet(this.state.selection)) {
        if (this.isContentArray(this.state.selection)) {
          selection = this.state.selection;
        } else {
          selection = options.filter((v) => v.value == this.state.selection);
        }

        if (selection.length === 0) {
          selection = options.filter(
            (v) => v.value == this.state.selection.value,
          );
        }
      } else if (IsSet(this.props.value)) {
        if (this.isContentArray(this.props.value)) {
          selection = this.props.value;
        } else {
          selection = options.filter((v) => v.value == this.props.value);
        }

        if (selection.length === 0) {
          selection = options.filter((v) => v.value == this.props.value.value);
        }
      }
    }

    return (
      <div>
        <InputLabel>{this.props.label}</InputLabel>
        <StyledFilterSelector
          {...passThroughProps}
          components={{ MenuList: WindowedMenuList }}
          className={className}
          classNamePrefix="filter-selector"
          classNames={{
            control: (state) =>
              state.isFocused
                ? "is-focused control-filter-selector "
                : " control-filter-selector ",
          }}
          styles={{
            placeholder: (base) => ({
              ...base,
              fontSize: "14px",
              fontWeight: 400,
              fontStyle: "oblique",
            }),
          }}
          focused={this.state.focused}
          cacheOptions
          defaultOptions
          onChange={this.onChange}
          defaultValue={selection}
          options={options}
          noOptionsMessage={(a) =>
            !this.isEmpty(a.inputValue) && a.inputValue.length > 2
              ? "No options found"
              : "Type to search"
          }
          isDisabled={this.props.disabled}
        />
      </div>
    );
  }

  isEmpty(value) {
    return (
      value == null || (typeof value === "string" && value.trim().length === 0)
    );
  }

  onChange(event) {
    this.setState({ selection: event });
    this.props.onChange(event);
  }
}

export default Selector;
export { FilterSelector, MultiSelect };
