import React, { Component } from "react";
import { Form } from "react-bootstrap";
import {
  DeleteButton,
  MainActionButton,
  TableButton,
} from "../../common/Buttons";
import { TableFrontend } from "../../common/Tables";
import { InternalLink } from "../../common/Link";
import { FormatDateTime, FormatDateTimeMonospace } from "../../common/Utils";
import ZoneComparisonDialog from "../common/ZoneComparisonDialog";
import SchedulePublishZonesDialog from "../common/SchedulePublishZonesDialog";

class PublishPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedZoneIds: [],
      zoneComparisonUrl: null,
      showZoneComparisonDialog: false,
      showSchedulePublishZonesDialog: false,
      submit: false,
      errorMessage: null,
      reloadFlag: false,
    };

    this.isSelected = this.isSelected.bind(this);
    this.toggleOne = this.toggleOne.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.publishZones = this.publishZones.bind(this);
    this.restoreZones = this.restoreZones.bind(this);
    this.schedulePublishZones = this.schedulePublishZones.bind(this);
    this.onSchedulePublishZonesCancel =
      this.onSchedulePublishZonesCancel.bind(this);
    this.onSchedulePublishZonesOk = this.onSchedulePublishZonesOk.bind(this);
    this.unschedulePublishZones = this.unschedulePublishZones.bind(this);
    this.showZoneComparison = this.showZoneComparison.bind(this);
  }

  componentDidMount() {
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Publish",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);
  }

  isSelected(zoneId) {
    let selectedZoneIds = this.state.selectedZoneIds;
    return selectedZoneIds.includes(zoneId);
  }

  toggleOne(event) {
    let zoneId = parseInt(event.target.id, 10);
    let selectedZoneIds = this.state.selectedZoneIds;

    if (selectedZoneIds.includes(zoneId)) {
      selectedZoneIds = selectedZoneIds.filter((o) => o !== zoneId);
    } else {
      selectedZoneIds.push(zoneId);
    }

    this.setState({ selectedZoneIds: selectedZoneIds });
  }

  toggleAll(event) {
    let selectedZoneIds = [];

    if (event.target.checked) {
      selectedZoneIds = this.refs.table.state.currentRows.map((o) =>
        parseInt(o.id, 10),
      );
    }

    this.setState({ selectedZoneIds: selectedZoneIds });
  }

  reloadPendingZones() {
    this.setState({ reloadFlag: !this.state.reloadFlag, selectedZoneIds: [] });
  }

  publishZones() {
    if (!this.validateSelectedZoneIds()) {
      return;
    }
    const errorFun = (msg) => {
      this.props.window.showErrorFunc(msg);
    };
    let url = this.props.session.getModules().dnsadmin.links["publishZones"];
    this.props.session.backendPost(
      url,
      {
        zoneIds: this.state.selectedZoneIds,
      },
      () => {
        const message = "Pending zones published successfully";
        this.props.window.showSuccessFunc(message);
        this.reloadPendingZones();
      },
      errorFun,
    );
  }

  restoreZones() {
    if (!this.validateSelectedZoneIds()) {
      return;
    }
    const errorFun = (msg) => {
      this.props.window.showErrorFunc(msg);
    };
    let url = this.props.session.getModules().dnsadmin.links["restoreZones"];
    this.props.session.backendPost(
      url,
      {
        zoneIds: this.state.selectedZoneIds,
      },
      () => {
        const message = "Restored zones successfully";
        this.props.window.showSuccessFunc(message);
        this.reloadPendingZones();
      },
      errorFun,
    );
  }

  schedulePublishZones() {
    if (!this.validateSelectedZoneIds()) {
      return;
    }

    this.setState({ showSchedulePublishZonesDialog: true, submit: false });
  }

  onSchedulePublishZonesCancel() {
    this.setState({
      showSchedulePublishZonesDialog: false,
      errorMessage: null,
      submit: false,
    });
  }

  onSchedulePublishZonesOk(data) {
    const errorFun = (msg) => {
      this.setState({ submit: false, errorMessage: msg });
    };
    let url =
      this.props.session.getModules().dnsadmin.links["schedulePublishZones"];
    this.setState({ submit: true });
    this.props.session.backendPost(
      url,
      {
        zoneIds: this.state.selectedZoneIds,
        when: data.when,
      },
      () => {
        this.setState({
          showSchedulePublishZonesDialog: false,
          submit: false,
          errorMessage: null,
        });
        const message = "Scheduled zone publications successfully";
        this.props.window.showSuccessFunc(message);
        this.reloadPendingZones();
      },
      errorFun,
    );
  }

  unschedulePublishZones() {
    if (!this.validateSelectedZoneIds()) {
      return;
    }

    const errorFun = (msg) => {
      this.props.window.showErrorFunc(msg);
    };
    let url =
      this.props.session.getModules().dnsadmin.links["unschedulePublishZones"];
    this.props.session.backendPost(
      url,
      {
        zoneIds: this.state.selectedZoneIds,
      },
      () => {
        const message = "Cancelled scheduled publications successfully";
        this.props.window.showSuccessFunc(message);
        this.reloadPendingZones();
      },
      errorFun,
    );
  }

  validateSelectedZoneIds() {
    if (this.state.selectedZoneIds.length === 0) {
      const message = "You must select at least one pending zone";
      this.props.window.showErrorFunc(message);
      return false;
    }
    return true;
  }

  showZoneComparison(url) {
    this.setState({ showZoneComparisonDialog: true, zoneComparisonUrl: url });
  }

  closeZoneComparison = () => {
    this.setState({ showZoneComparisonDialog: false });
  };

  getColumns() {
    const checkboxStyle = {
      margin: 0,
    };
    return [
      {
        label: <Form.Check style={checkboxStyle} onChange={this.toggleAll} />,
        style: { width: "50px" },
        contentFunction: (r) => (
          <Form.Check
            style={checkboxStyle}
            id={r.id}
            checked={this.isSelected(parseInt(r.id, 10))}
            onChange={this.toggleOne}
          />
        ),
      },
      {
        label: "Zone",
        name: "zoneName",
        sortable: true,
        filterable: true,
        contentFunction: (r) => (
          <InternalLink
            to={"/dnsadmin/zones/" + r.id}
            text={r.zoneName}
            className="table-link"
          />
        ),
      },
      {
        label: "Scheduled At",
        name: "publicationDate",
        sortable: true,
        filterable: true,
        contentFunction: (r) => (
          <div>{FormatDateTimeMonospace(r.publicationDate)}</div>
        ),
        filterFunction: (r) => FormatDateTime(r.publicationDate),
      },
      {
        label: "Scheduled By",
        name: "publicationUser",
        sortable: true,
        filterable: true,
      },
      {
        style: { width: "180px" },
        contentFunction: (r) => (
          <TableButton
            onClick={() => this.showZoneComparison(r.links.self)}
            icon="eye"
            text="Review"
            className="secondary"
          />
        ),
      },
    ];
  }

  render() {
    let disabledTooltip = "You must select at least one pending zone";

    const actions = [
      <MainActionButton
        key="publish"
        onClick={this.publishZones}
        icon="paper-plane"
        text="Publish"
        disabled={this.state.selectedZoneIds.length === 0}
        disabledTooltip={disabledTooltip}
      />,
      <MainActionButton
        key="schedule"
        onClick={this.schedulePublishZones}
        icon="calendar-alt"
        text="Schedule"
        disabled={this.state.selectedZoneIds.length === 0}
        disabledTooltip={disabledTooltip}
      />,
      <DeleteButton
        key="cancel"
        onClick={this.unschedulePublishZones}
        icon="calendar-times"
        text="Cancel scheduled"
        disabled={this.state.selectedZoneIds.length === 0}
        disabledTooltip={disabledTooltip}
      />,
      <DeleteButton
        key="revert"
        onClick={this.restoreZones}
        icon="redo-alt"
        text="Revert pending"
        disabled={this.state.selectedZoneIds.length === 0}
        disabledTooltip={disabledTooltip}
      />,
    ];
    const url = this.props.session.getModules().dnsadmin.links.pendingZones;
    return (
      <div>
        <TableFrontend
          id="zone.publish"
          ref={"table"}
          session={this.props.session}
          window={this.props.window}
          actions={actions}
          filterable={true}
          columns={this.getColumns()}
          sorting="zoneName"
          url={url}
          reload={this.state.reloadFlag}
        />
        <ZoneComparisonDialog
          session={this.props.session}
          url={this.state.zoneComparisonUrl}
          show={this.state.showZoneComparisonDialog}
          title="Pending Changes"
          onClose={this.closeZoneComparison}
        />
        <SchedulePublishZonesDialog
          showDialog={this.state.showSchedulePublishZonesDialog}
          onCancel={this.onSchedulePublishZonesCancel}
          onOk={this.onSchedulePublishZonesOk}
          submit={this.state.submit}
          errorMessage={this.state.errorMessage}
        />
      </div>
    );
  }
}

export default PublishPage;
