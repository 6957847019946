import React, { Component } from "react";
import { TableFrontend } from "../common/Tables";
import { FormatDate, FormatDateMonospace } from "../common/Utils";
import Colors from "../common/theme/Colors";
import styled from "styled-components";

const StatusColumn = styled.div`
  &.success {
    color: ${Colors.success};
  }
  &.fail {
    color: ${Colors.error};
  }
`;
class ActivitiesTab extends Component {
  constructor(props) {
    super(props);
  }

  getStatus = (r) => {
    let className;
    switch (r.statusType) {
      case "SUCCESS":
        className = "success";
        break;
      case "FAILURE":
        className = "fail";
        break;
      default:
        className = "";
    }
    return <StatusColumn className={className}>{r.status}</StatusColumn>;
  };

  render() {
    const columns = [
      {
        label: "Start Date",
        name: "startDate",
        sortable: true,
        filterable: true,
        contentFunction: (r) => FormatDateMonospace(r.startDate),
        filterFunction: (r) => FormatDate(r.startDate),
      },
      {
        label: "Action",
        name: "type",
        sortable: true,
        filterable: true,
      },
      {
        label: "Status",
        name: "status",
        sortable: true,
        filterable: true,
        contentFunction: (r) => this.getStatus(r),
      },
    ];

    const { session, window } = this.props;
    const url = this.props.project.links.actions;

    return (
      <TableFrontend
        id="project.action"
        session={session}
        window={window}
        filterable={true}
        columns={columns}
        sorting="startDate"
        url={url}
      />
    );
  }
}

export default ActivitiesTab;
