import React, { Component } from "react";
import { TableBase } from "../../common/Tables";

class ClassesTab extends Component {
  constructor(props) {
    super(props);
  }

  getClassDetails = (ipRight) => {
    const classes = ipRight.classes;
    if (!classes && classes.length < 1) {
      return;
    }

    const classColumns = [
      {
        label: "Class Number",
        name: "id",
        style: { width: "140px" },
      },
      {
        label: "Class Description",
        name: "text",
      },
    ];

    return [
      <TableBase
        id="ipRight.classes"
        columns={classColumns}
        rows={classes}
        noTableHeader={false}
      />,
    ];
  };

  render() {
    return <div>{this.getClassDetails(this.props.ipRight)}</div>;
  }
}

export default ClassesTab;
