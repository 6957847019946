import React, { Component } from "react";
import ReadOnlyForm from "../../common/ReadOnlyForm";
import { FormatDateMonospace } from "../../common/Utils";
import styled from "styled-components";
import Colors from "../../common/theme/Colors";

const DivStyle = styled.div`
  border: 1px solid ${Colors.border};
  background-color: ${Colors.white};
  table {
    margin-top: 15px;
  }
  img {
    max-height: 700px;
    max-width: 700px;
  }

  padding-left: 20px;
  padding-bottom: 20px;

  .section-title {
    color: ${Colors.tertiary};
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.5px;
  }

  > .container-fluid > .actions {
    padding: 15px 0px 10px 0px;
  }
`;

class DetailsTab extends Component {
  constructor(props) {
    super(props);
  }

  capitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  };

  getBasicInfo = (ipRight) => {
    const content = [
      {
        label: "CatchWord",
        value: ipRight.catchWord,
      },
      {
        label: "",
        value: "",
      },
      {
        label: "Type",
        value: ipRight.productType,
      },
      {
        label: "Category",
        value: ipRight.category,
      },
      {
        label: "Office",
        value: ipRight.office,
      },
      {
        label: "Status",
        value: this.capitalize(ipRight.legalProductState),
      },
      {
        label: "",
        value: "",
      },
      {
        label: "Application Number",
        value: ipRight.applicationNumber,
      },
      {
        label: "Registration Number",
        value: ipRight.registrationNumber,
      },
      {
        label: "",
        value: "",
      },
      {
        label: "Application Date",
        value: FormatDateMonospace(ipRight.applicationDate),
      },
      {
        label: "Registration Date",
        value: FormatDateMonospace(ipRight.registrationDate),
      },
      {
        label: "Renewal Date",
        value: FormatDateMonospace(ipRight.renewalDate),
      },
      {
        label: "",
        value: "",
      },
      {
        label: "Case Number",
        value: ipRight.caseNumber,
      },
    ];

    return <ReadOnlyForm wide="true" content={content} />;
  };

  render() {
    return <DivStyle>{this.getBasicInfo(this.props.ipRight)}</DivStyle>;
  }
}

export default DetailsTab;
