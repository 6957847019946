import React, { Component } from "react";
import { TableBase } from "../../common/Tables";
import { Col, Container, Row, Modal } from "react-bootstrap";
import { ExternalLink } from "../../common/Link";
import { FormatDateMonospace } from "../../common/Utils";
import fileDownload from "js-file-download";
import Colors from "../../common/theme/Colors";

class DocumentsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createOrderDialogDelegateShow: false,
      documents: null,
      documentError: false,
      downloadingDocs: new Map(),
    };
  }

  componentDidMount() {
    this.reloadDocuments();
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.reloadDocuments();
    }
  }

  reloadDocuments = () => {
    this.setState({ documents: null, documentError: false });

    if (this.props.ipRight?.links.documents) {
      this.props.session.backendGet(
        this.props.ipRight.links.documents,
        (response) => {
          this.setState({ documents: response });
        },
        () => {
          this.setState({ documentError: true });
        },
      );
    }
  };

  getDocuments = () => {
    let parts = [];

    let loading = (
      <Row key="loading">
        <Col xs={12}>
          <pre>Loading, please wait</pre>
        </Col>
      </Row>
    );

    let error = (
      <Row key="error">
        <Col xs={12}>
          <pre>Failed fetching data. Please try again later.</pre>
        </Col>
      </Row>
    );

    if (this.state.documentError) {
      parts.push(error);
    } else if (this.state.documents === null) {
      parts.push(loading);
    } else if (this.state.documents.length > 0) {
      parts.push(this.getDocumentTable());
    } else {
      return;
    }

    return <div>{parts}</div>;
  };

  getLink = (document) => {
    let icon = this.state.downloadingDocs.has(document.fileName)
      ? "spinner"
      : "";
    return (
      <ExternalLink
        onClick={() => this.onDownload(document)}
        tooltip={"Click to download"}
        text={document.description}
        style={{
          textDecoration: "underline",
          color: Colors.tertiary,
          cursor: "pointer",
        }}
        icon={icon}
      />
    );
  };

  onDownload = (document) => {
    const url =
      this.props.ipRight.links.documents +
      "/" +
      encodeURIComponent(document.fileName);
    const fileName = document.description + "." + document.extension;
    this.beforeDownload(document.fileName);
    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, fileName);
        this.afterDownload(document.fileName);
      },
      () => {
        this.props.window.showErrorFunc(
          "Document download failed. Please try again later",
        );
        this.afterDownload(document.fileName);
      },
    );
  };

  beforeDownload = (key) => {
    let docs = this.state.downloadingDocs;
    if (!docs.has(key)) {
      docs.set(key, null);
    }
    this.setState({ downloadingDocs: docs });
  };

  afterDownload = (key) => {
    let docs = this.state.downloadingDocs;
    docs.delete(key);
    this.setState({ downloadingDocs: docs });
  };

  getDocumentTable = () => {
    const columns = [
      {
        label: "Name",
        name: "description",
        contentFunction: (r) => this.getLink(r),
      },
      {
        label: "Created At",
        name: "date",
        style: { width: "150px" },
        contentFunction: (r) => FormatDateMonospace(r.date),
      },
    ];

    return (
      <TableBase
        id="ipright.documents"
        columns={columns}
        rows={this.state.documents}
      />
    );
  };

  render() {
    return <div>{this.getDocuments(this.state.documents)}</div>;
  }
}

export default DocumentsTab;
