import React, { Component } from "react";
import { TableFrontend } from "../common/Tables";
import { FormatDate, FormatDateMonospace } from "../common/Utils";
import { SwitchCheckbox } from "../common/theme/Theme";

class OrdersTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onlyActive: false,
    };
  }

  handleCheckboxOnChange = (event) => {
    this.setState({ onlyActive: event.target.checked });
  };

  filterByState = (rows) => {
    if (this.state.onlyActive) {
      rows = rows.filter((o) => o.active);
    }
    return rows;
  };

  render() {
    const columns = [
      {
        label: "Product Name",
        name: "productName",
        sortable: true,
        filterable: true,
      },
      {
        label: "Type",
        name: "operation",
        sortable: true,
        filterable: true,
      },
      {
        label: "State",
        name: "state",
        sortable: true,
        filterable: true,
      },
      {
        label: "Created By",
        name: "createdBy",
        sortable: true,
        style: { width: "150px" },
      },
      {
        label: "Created At",
        name: "createdAt",
        sortable: true,
        filterable: true,
        style: { width: "150px" },
        contentFunction: (r) => FormatDateMonospace(r.createdAt),
        filterFunction: (r) => FormatDate(r.createdAt),
      },
      {
        label: "Completed At",
        name: "completedAt",
        sortable: true,
        filterable: true,
        contentFunction: (r) => FormatDateMonospace(r.completedAt),
        filterFunction: (r) => FormatDate(r.completedAt),
      },
    ];

    const { session, window } = this.props;
    const url = this.props.project.links.orders;
    const filters = [
      SwitchCheckbox(
        "Only display orders in progress",
        this.state.onlyActive,
        (o) => this.handleCheckboxOnChange(o),
      ),
    ];

    return (
      <TableFrontend
        id="project.order"
        session={session}
        window={window}
        otherFilters={filters}
        filterable={true}
        columns={columns}
        sorting="productName"
        url={url}
        responseFunction={this.filterByState}
        reload={this.state.onlyActive}
      />
    );
  }
}

export default OrdersTab;
