import React, { Component } from "react";
import { TableBase } from "../../common/Tables";

class PartiesTab extends Component {
  constructor(props) {
    super(props);
  }

  getPartiesDetails = (ipRight) => {
    const parties = ipRight.caseParties;
    if (!parties && parties.length < 1) {
      return;
    }

    const columns = [
      {
        label: "Role",
        name: "partyRole",
        style: { width: "100px" },
      },
      {
        label: "Address",
        name: "address",
        contentFunction: (r) => this.formatAddress(r),
      },
    ];

    return [
      <TableBase
        id="ipright.parties"
        columns={columns}
        rows={parties}
        noTableHeader={false}
      />,
    ];
  };

  render() {
    return <div>{this.getPartiesDetails(this.props.ipRight)}</div>;
  }

  formatAddress = (caseParty) => {
    return <div style={{ whiteSpace: "pre-wrap" }}>{caseParty.address}</div>;
  };
}

export default PartiesTab;
