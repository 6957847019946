import React, { Component } from "react";
import { TableBackend, TableFilter } from "../../common/Tables";
import { FormatOrganisation } from "../../common/Utils";
import styled from "styled-components";
import { MainActionButton, TableButton } from "../../common/Buttons";
import SearchableOrganisationFilter from "../../common/SearchableOrganisationFilter";
import NameserverSetDialog from "./NameserverSetDialog";
import { SwitchCheckbox } from "../../common/theme/Theme";

const PageStyle = styled.div`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
  }
`;
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export default class NameserverSetList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        organisationId: "",
        freetext: "",
        onlyOwned: true,
        reloadFlag: false,
      },
      showNameserverDialog: false,
      conf: null,
      submit: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Nameservers",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);
  }

  getUrl = (sorting, expanding, parameters) => {
    let url = this.props.session.getModules().domainadmin.links.nameserverSets;
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&organisationIdFilter=" + parameters.organisationId;
    url += "&onlyOwnedFilter=" + parameters.onlyOwned;
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    return url;
  };

  onRemove = (row) => {
    const message = <div>Are you sure you want to remove the nameservers?</div>;
    this.props.window.showConfirmFunc(message, () => {
      let url = row.links.self;
      this.props.session.backendDelete(url, () => {
        this.props.window.showSuccessFunc("Nameservers successfully deleted");
        this.reloadPage();
      });
    });
  };

  onEdit = (row) => {
    const url = row.links.configuration;
    this.props.session.backendGet(url, (response) => {
      this.setState({ conf: response, showNameserverDialog: true });
    });
  };

  onView = (row) => {
    const url = row.links.self;
    this.props.session.backendGet(url, (response) => {
      this.setState({ conf: response, showNameserverDialog: true });
    });
  };

  onCreate = () => {
    const url =
      this.props.session.getModules().domainadmin.links
        .nameserverSetConfiguration;
    this.props.session.backendGet(url, (response) => {
      this.setState({ conf: response, showNameserverDialog: true });
    });
  };

  closeDialog = () => {
    this.setState({
      conf: null,
      showNameserverDialog: false,
      errorMessage: null,
    });
  };

  onSubmit = (validate) => {
    this.setState({ submit: true });
    const successCreateFun = () => {
      this.closeDialog();
      this.setState({ submit: false });
      this.reloadPage();
      const message = "Nameservers successfully created";
      this.props.window.showSuccessFunc(message);
    };
    const successEditFun = () => {
      this.closeDialog();
      this.setState({ submit: false });
      this.reloadPage();
      const message = "Nameservers successfully edited";
      this.props.window.showSuccessFunc(message);
    };
    const errorFun = (msg) => {
      this.setState({ submit: false, errorMessage: msg });
    };

    if (this.state.conf.object.id) {
      this.setState({ errorMessage: null });
      const url = this.state.conf.object.links.self + "?validate=" + validate;
      this.props.session.backendPut(
        url,
        this.state.conf.object,
        successEditFun,
        errorFun,
      );
    } else {
      this.setState({ errorMessage: null });
      const url =
        this.props.session.getModules().domainadmin.links.nameserverSets +
        "?validate=" +
        validate;
      this.props.session.backendPost(
        url,
        this.state.conf.object,
        successCreateFun,
        errorFun,
      );
    }
  };

  getNameservers = (nameservers) => {
    let elements = [];
    nameservers.forEach((v) =>
      elements.push(
        <div key={v}>
          {v}
          <br></br>
        </div>,
      ),
    );
    return elements;
  };

  reloadPage() {
    this.setState({
      parameters: Object.assign({}, this.state.parameters, {
        reloadFlag: !this.state.parameters.reloadFlag,
      }),
    });
  }

  getOrganisation = (r) => {
    if (r.organisation == null) {
      return "(Abion Shared)";
    } else {
      return FormatOrganisation(
        r.organisation,
        this.props.session.getApplication().organisationPrefix,
      );
    }
  };

  getButtons = (r) => {
    let elements = [];
    if (r.editable) {
      elements.push(
        <TableButton
          key="edit"
          onClick={() => this.onEdit(r)}
          icon="edit"
          prefix={"fas"}
          text="Edit"
          className={"secondary"}
        />,
      );
      elements.push(
        <TableButton
          key="remove"
          onClick={() => this.onRemove(r)}
          icon="trash-alt"
          prefix={"fas"}
          text="Remove"
          className="remove"
        />,
      );
    }
    return elements;
  };

  render() {
    let actions = [];

    if (this.props.session.hasRole("DOMAIN_ADMIN_EDIT")) {
      actions.push(
        <MainActionButton
          key="create"
          onClick={this.onCreate}
          text="Create Nameservers"
          icon="plus"
          prefix="fas"
          tooltip="Create a set of nameservers to use in order dialog."
        />,
      );
    }

    const filters = [
      <StyledDiv key="search-div">
        {SwitchCheckbox(
          "Only display own nameservers",
          this.state.parameters.onlyOwned,
          (o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                onlyOwned: o.target.checked,
              }),
            }),
        )}
        <SearchableOrganisationFilter
          key="organisationFilter"
          session={this.props.session}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                organisationId: o,
              }),
            })
          }
        />
        <TableFilter
          value={this.state.parameters.freetext}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                freetext: o,
              }),
            })
          }
        />
      </StyledDiv>,
    ];

    const columns = [
      {
        label: "Nameservers",
        name: "nameservers",
        sortable: true,
        contentFunction: (r) => <div>{this.getNameservers(r.nameservers)}</div>,
      },
      {
        label: "Organisation",
        name: "accountDescription",
        sortable: false,
        contentFunction: (r) => this.getOrganisation(r),
      },
      {
        label: "Comments",
        name: "comments",
        sortable: true,
        contentFunction: (r) => (
          <div style={{ whiteSpace: "pre-wrap" }}>{r.comments}</div>
        ),
      },
      {
        style: { width: "200px" },
        contentFunction: (r) => <div>{this.getButtons(r)}</div>,
      },
    ];

    return (
      <PageStyle>
        <TableBackend
          id="namserverset"
          session={this.props.session}
          window={this.props.window}
          actions={actions}
          filters={filters}
          columns={columns}
          sorting="nameservers"
          parameters={this.state.parameters}
          urlFunction={this.getUrl}
        />
        <NameserverSetDialog
          session={this.props.session}
          window={this.props.window}
          conf={this.state.conf}
          show={this.state.showNameserverDialog}
          onCancel={this.closeDialog}
          onConfirm={this.onSubmit}
          submit={this.state.submit}
          errorMessage={this.state.errorMessage}
        />
      </PageStyle>
    );
  }
}
