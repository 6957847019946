import React, { Component } from "react";
import { Dropdown, Form } from "react-bootstrap";
import styled from "styled-components";
import fileDownload from "js-file-download";
import { FormatDate, FormatDateMonospace } from "../../common/Utils";
import {
  BlockingActionButton,
  MainActionButton,
  TableButton,
} from "../../common/Buttons";
import { TableBackend, TableFilter } from "../../common/Tables";
import UpdateCommentsDialog from "../../common/UpdateCommentsDialog";
import CreateOrderDialog from "../../order/CreateOrderDialog";
import UpdateProjectDialog from "../UpdateProjectDialog";
import UpdateRiskDialog from "../UpdateRiskDialog";
import WatchFilter from "../WatchFilter";
import DiscoveredFilter from "../DiscoveredFilter";
import RiskFilter from "../RiskFilter";
import ProjectComponent from "../ProjectComponent";
import RiskComponent from "../RiskComponent";
import CommentComponent from "../CommentComponent";
import DomainMonitorHitDialog from "./DomainMonitorHitDialog";

const PageStyle = styled.div`
  .dropdown {
    vertical-align: top;
    margin-left: 20px;
  }
  .dropdown-menu {
    max-height: 400px;
    overflow: auto;
  }
  .checkbox {
    margin: 0px;
  }
`;

export default class DomainMonitorHitList extends Component {
  riskMap = new Map();
  commentMap = new Map();
  descriptionMap = new Map();
  watchIdMap = new Map();

  constructor(props) {
    super(props);
    this.state = {
      watchFilterOptions: [],
      selectedWatchDescription: "Any Watch",
      parameters: {
        freetext: "",
        discovered: "30",
        risk: "",
        selectedWatchId: 0,
        reloadFlag: false,
      },
      domainMonitorName: null,
      selectedIds: [],
      hitDialogShow: false,
      hitDialogUrl: null,
      updateProjectDialogShow: false,
      updateProjectDialogIds: [],
      updateProjectDialogSubmit: false,
      updateProjectDialogErrorMessage: null,
      updateRiskDialogShow: false,
      updateRiskDialogIds: [],
      updateCommentDialogShow: false,
      updateCommentDialogUrl: null,
      updateCommentDialogId: null,
      updateCommentDialogSubmit: false,
      updateCommentDialogErrorMessage: null,
      createOrderDialogShow: false,
      exported: 0,
    };
  }

  componentDidMount() {
    let url = this.props.session.getModules().monitoring.links.domainmonitors;
    url += "?sort=description";
    url += "&page[limit]=0";
    url += "&accountIdFilter=";
    url += "&includeHits=false";
    this.setState({ watchOptionsLoaded: false });
    this.props.session.backendGet(url, (response) => {
      let views = [
        {
          label: "Home",
          url: "/",
        },
        {
          label: "Watches",
          url: "/monitoring/watches",
        },
        {
          label: "Domain Monitor Hits",
          url: null,
        },
      ];

      const domainMonitorId = this.props.location.state?.domainMonitorId;
      if (domainMonitorId) {
        let domainMonitor = response.objects.find(
          (watch) => watch.id === domainMonitorId,
        );
        if (domainMonitor) {
          this.setState({
            selectedWatchDescription: domainMonitor.description,
            parameters: Object.assign({}, this.state.parameters, {
              selectedWatchId: domainMonitor.id,
            }),
          });
        }
      }
      let watchFilterOptions = this.getWatchFilterOptions(response);

      this.setState({
        watchFilterOptions: watchFilterOptions,
        watchOptionsLoaded: true,
      });

      this.props.window.setBreadcrumbViews(views);
      this.props.window.setPageTip(null);
    });
  }

  reload = () => {
    this.setState({
      parameters: Object.assign({}, this.state.parameters, {
        reloadFlag: !this.state.parameters.reloadFlag,
      }),
    });
  };

  getWatchFilterOptions = (response) => {
    let watches = [];
    watches.push(
      <Dropdown.Item
        key="any-watch-id-filter"
        onClick={(o) => {
          this.setState({
            selectedWatchDescription: "Any Watch",
            parameters: Object.assign({}, this.state.parameters, {
              selectedWatchId: 0,
            }),
          });
        }}
      >
        Any Watch
      </Dropdown.Item>,
    );
    return watches.concat(
      response.objects.map((watch) => {
        return (
          <Dropdown.Item
            key={`watch-key-${watch.id}`}
            onClick={() => {
              this.setState({
                selectedWatchDescription: watch.description,
                parameters: Object.assign({}, this.state.parameters, {
                  selectedWatchId: watch.id,
                }),
              });
            }}
          >
            {watch.description}
          </Dropdown.Item>
        );
      }),
    );
  };

  showHitDialog = (url) => {
    this.setState({
      hitDialogShow: true,
      hitDialogUrl: url,
    });
  };

  hideHitDialog = () => {
    this.setState({
      hitDialogShow: false,
      hitDialogUrl: null,
    });
  };

  onUpdateProjectDialogShow = (hitId) => {
    this.setState({
      updateProjectDialogShow: true,
      updateProjectDialogIds: [hitId],
    });
  };

  onBulkUpdateProjectDialogShow = () => {
    this.setState({
      updateProjectDialogShow: true,
      updateProjectDialogIds: this.state.selectedIds,
    });
  };

  onUpdateProjectDialogSave = (description, comments) => {
    const onSuccess = () => {
      if (this.state.updateProjectDialogIds.length == 1) {
        this.props.window.showSuccessFunc("Case created successfully");
      } else {
        this.props.window.showSuccessFunc("Cases created successfully");
      }
      this.onUpdateProjectDialogCancel();
      this.reload();
    };

    const onError = (msg) => {
      this.setState({
        updateProjectDialogSubmit: false,
        updateProjectDialogErrorMessage: msg,
      });
    };

    const body = this.state.updateProjectDialogIds.map((hitId) => ({
      hitId: hitId,
      watchId: this.watchIdMap.get(hitId),
      description: description,
      comments: comments,
    }));

    this.props.session.backendPut(
      this.props.session.getModules().monitoring.links.domainmonitorshitproject,
      body,
      onSuccess,
      onError,
    );
  };

  onUpdateProjectDialogCancel = () => {
    this.setState({
      updateProjectDialogShow: false,
      updateProjectDialogIds: [],
      updateProjectDialogSubmit: false,
      updateProjectDialogErrorMessage: null,
    });
  };

  onUpdateRiskDialogShow = (hitId) => {
    this.setState({
      updateRiskDialogShow: true,
      updateRiskDialogIds: [hitId],
    });
  };

  onBulkUpdateRiskDialogShow = () => {
    this.setState({
      updateRiskDialogShow: true,
      updateRiskDialogIds: this.state.selectedIds,
    });
  };

  onUpdateRiskDialogSave = (newRisk) => {
    const callback = () => {
      this.state.updateRiskDialogIds.forEach((hitId) =>
        this.riskMap.set(hitId, newRisk),
      );
      this.props.window.showSuccessFunc("Risk updated successfully");
      this.onUpdateRiskDialogCancel();
      this.reload();
    };
    const requestBody = this.state.updateRiskDialogIds.map((hitId) => ({
      watchId: this.watchIdMap.get(hitId),
      hitId: hitId,
      risk: newRisk,
    }));

    this.props.session.backendPut(
      this.props.session.getModules().monitoring.links.domainmonitorshitrisk,
      requestBody,
      callback,
    );
  };

  onUpdateRiskDialogCancel = () => {
    this.setState({
      updateRiskDialogShow: false,
      updateRiskDialogIds: [],
    });
  };

  onUpdateCommentDialogShow = (url, id) => {
    this.setState({
      updateCommentDialogShow: true,
      updateCommentDialogUrl: url,
      updateCommentDialogId: id,
    });
  };

  onUpdateCommentDialogSave = (newComment) => {
    const callback = () => {
      this.commentMap.set(this.state.updateCommentDialogId, newComment);
      this.props.window.showSuccessFunc("Comments updated successfully");
      this.onUpdateCommentDialogCancel();
      this.reload();
    };
    const errorFun = (msg) => {
      this.setState({
        updateCommentDialogSubmit: false,
        updateCommentDialogErrorMessage: msg,
      });
    };
    this.setState({ updateCommentDialogSubmit: true });
    this.props.session.backendPut(
      this.state.updateCommentDialogUrl,
      { value: newComment },
      callback,
      errorFun,
    );
  };

  onUpdateCommentDialogCancel = () => {
    this.setState({
      updateCommentDialogShow: false,
      updateCommentDialogUrl: null,
      updateCommentDialogId: null,
      updateCommentDialogSubmit: false,
      updateCommentDialogErrorMessage: null,
    });
  };

  getCreateOrderParameters = () => {
    let ids = this.state.selectedIds;
    let parameters = {};

    if (ids.length === 0) {
      return parameters;
    }

    let configuration = "";

    for (let i = 0; i < ids.length; i++) {
      let description = this.descriptionMap.get(ids[i]);
      let risk = this.riskMap.get(ids[i]);
      let comment = this.commentMap.get(ids[i]);

      configuration += description;

      if (risk != null) {
        configuration +=
          " " + risk.substring(0, 1) + risk.substring(1).toLowerCase();
      }
      if (comment != null) {
        configuration += "\n- " + comment;
      }

      configuration += "\n";
    }

    parameters.description = "Investigate Hits from Domain Monitors";
    parameters.configuration = configuration;

    return parameters;
  };

  isSelected = (id) => {
    return this.state.selectedIds.includes(id);
  };

  toggleOne = (id) => {
    let selectedIds = this.state.selectedIds;

    if (selectedIds.includes(id)) {
      selectedIds = selectedIds.filter((o) => o !== id);
    } else {
      selectedIds.push(id);
    }

    this.setState({ selectedIds: selectedIds });
  };

  toggleAll = (event) => {
    let selectedIds = [];

    if (event.target.checked) {
      selectedIds = Array.from(this.descriptionMap.keys());
    }

    this.setState({ selectedIds: selectedIds });
  };

  clearAll = (event) => {
    this.setState({ selectedIds: [] });
  };

  exportHits = () => {
    let url =
      this.props.session.getModules().monitoring.links.domainmonitorsexporthits;
    url += "?watchIdFilter=" + this.state.parameters.selectedWatchId;
    url +=
      "&discoveredFromFilter=" +
      encodeURIComponent(this.state.parameters.discovered);
    url += "&riskFilter=" + encodeURIComponent(this.state.parameters.risk);
    url +=
      "&freetextFilter=" + encodeURIComponent(this.state.parameters.freetext);

    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, "Domain Monitor Hits.xlsx");
        this.setState({ exported: Date.now() });
      },
      (error) => {
        this.props.window.showErrorFunc(error);
        this.setState({ exported: Date.now() });
      },
    );
  };

  getUrl = (sorting, expanding, parameters) => {
    let url =
      this.props.session.getModules().monitoring.links.domainmonitorshits;
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&watchIdFilter=" + parameters.selectedWatchId;
    url += "&discoveredFromFilter=" + encodeURIComponent(parameters.discovered);
    url += "&riskFilter=" + encodeURIComponent(parameters.risk);
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    return url;
  };

  onResponse = (response) => {
    this.clearAll();
    this.riskMap.clear();
    this.commentMap.clear();
    this.descriptionMap.clear();
    this.watchIdMap.clear();
    response.objects.forEach((r) => this.riskMap.set(r.id, r.risk));
    response.objects.forEach((r) => this.commentMap.set(r.id, r.comment));
    response.objects.forEach((r) =>
      this.descriptionMap.set(r.id, this.getDescription(r)),
    );
    response.objects.forEach((r) => {
      this.watchIdMap.set(r.id, r.watchId);
    });

    return response;
  };

  getDescription = (hit) => {
    let host = " ";
    let recordType = " ";
    if (hit.hitType === "Zone") {
      if (hit.current) {
        const split = hit.current.split(",");
        host = host + split[0];
        recordType = recordType + split[1];
      } else if (hit.previous) {
        const split = hit.previous.split(",");
        host = host + split[0];
        recordType = recordType + split[1];
      }
    }
    return hit.hitType + host + recordType + " " + FormatDate(hit.discovered);
  };

  formatRecord = (type, record) => {
    if (type === "Web Page") {
      return "(see details)";
    }
    if (record === null) {
      return null;
    }
    let elements = [];
    const split = record.split("|");
    split.forEach((v) =>
      elements.push(
        <div key={v}>
          {v}
          <br></br>
        </div>,
      ),
    );
    return elements;
  };

  getDisabledInvestigateTooltip = (hasRole, hasSelection) => {
    if (!hasRole) {
      return "Your user account does not have privileges to order domain investigations, contact your client manager to activate this functionality.";
    } else if (!hasSelection) {
      return "You start by selecting domain monitor hits, then this function will be available.";
    }
    return "";
  };

  render() {
    let actions = [];

    actions.push(
      <MainActionButton
        key="investigate"
        onClick={() => this.setState({ createOrderDialogShow: true })}
        text="Investigate Selected Domains"
        icon="plus"
        prefix="fas"
        tooltip="Open the order dialog and fill in details to order new domain investigation for selected domains."
        disabled={
          !this.props.session.hasRole("ORDER_CREATE") ||
          this.state.selectedIds == null ||
          this.state.selectedIds.length === 0
        }
        disabledTooltip={this.getDisabledInvestigateTooltip(
          this.props.session.hasRole("ORDER_CREATE"),
          this.state.selectedIds != null && this.state.selectedIds.length > 0,
        )}
        invisible={this.props.session.hasRole("SYS_ADMIN")}
      />,
    );

    actions.push(
      <MainActionButton
        key="bulk-edit-project"
        onClick={this.onBulkUpdateProjectDialogShow}
        text={"Create Cases"}
        disabled={
          this.state.selectedIds == null || this.state.selectedIds.length === 0
        }
        icon="plus"
        prefix="fas"
        tooltip="Create cases for selected domain monitor hits."
        invisible={!this.props.session.hasRole("SYS_ADMIN")}
      />,
      <MainActionButton
        key="bulk-edit-risk"
        onClick={this.onBulkUpdateRiskDialogShow}
        text={"Update Risk"}
        disabled={
          this.state.selectedIds == null || this.state.selectedIds.length === 0
        }
        icon={"edit"}
        prefix={"fas"}
        tooltip="Bulk edit risks for selected domain monitor hits."
      />,
      <BlockingActionButton
        key="export"
        onClick={this.exportHits}
        reload={this.state.exported}
        text={"Export"}
        icon={"cloud-download"}
        prefix={"fas"}
        tooltip="Exports the domain monitor hits to an Excel file. Note that only rows matching current filter are exported."
      />,
    );

    const filters = [
      <WatchFilter
        key="watch-filter"
        title={this.state.selectedWatchDescription}
        options={this.state.watchFilterOptions}
      />,
      <RiskFilter
        key="risk-filter"
        value={this.state.parameters.risk}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, { risk: o }),
          })
        }
      />,
      <DiscoveredFilter
        key="discovered-filter"
        value={this.state.parameters.discovered}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              discovered: o,
            }),
          })
        }
      />,
      <TableFilter
        key="table-filter"
        value={this.state.parameters.freetext}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              freetext: o,
            }),
          })
        }
      />,
    ];

    let columns = [];

    columns.push({
      label: <Form.Check onChange={this.toggleAll} />,
      style: { width: "50px" },
      contentFunction: (r) => (
        <Form.Check
          checked={this.isSelected(r.id)}
          onChange={() => this.toggleOne(r.id)}
        />
      ),
    });

    columns.push(
      {
        label: "Change Type",
        name: "hitType",
        sortable: false,
      },
      {
        label: "New Value",
        name: "current",
        style: { width: "220px" },
        contentFunction: (r) => (
          <div>{this.formatRecord(r.hitType, r.current)}</div>
        ),
      },
      {
        label: "Discovered",
        name: "discovered",
        sortable: true,
        contentFunction: (r) => FormatDateMonospace(r.discovered),
      },
    );

    if (this.props.session.hasRole("SYS_ADMIN")) {
      columns.push({
        label: "Case",
        name: "project",
        sortable: true,
        contentFunction: (r) => (
          <ProjectComponent
            session={this.props.session}
            projectId={r.projectId}
            projectNumber={r.projectNumber}
            onEdit={() => this.onUpdateProjectDialogShow(r.id)}
          />
        ),
      });
    }

    columns.push(
      {
        label: "Risk",
        name: "risk",
        contentFunction: (r) => (
          <RiskComponent
            value={this.riskMap.get(r.id)}
            onEdit={() => this.onUpdateRiskDialogShow(r.id)}
          />
        ),
      },
      {
        label: "Comments",
        name: "comment",
        contentFunction: (r) => (
          <CommentComponent
            value={this.commentMap.get(r.id)}
            onEdit={() => this.onUpdateCommentDialogShow(r.links.comment, r.id)}
          />
        ),
      },
      {
        style: { width: "150px" },
        contentFunction: (r) => (
          <TableButton
            onClick={() => this.showHitDialog(r.links.self)}
            icon="eye"
            prefix={"fas"}
            text="Details"
            className={"secondary"}
          />
        ),
      },
    );

    let riskValue = "";
    if (this.state.updateRiskDialogIds.length === 1) {
      riskValue = this.riskMap.get(this.state.updateRiskDialogIds[0]);
    }

    return (
      <PageStyle>
        {this.state.watchOptionsLoaded && (
          <TableBackend
            id="watch.domainmonitor.hit"
            session={this.props.session}
            window={this.props.window}
            actions={actions}
            filters={filters}
            columns={columns}
            sorting="-discovered"
            parameters={this.state.parameters}
            urlFunction={this.getUrl}
            responseFunction={this.onResponse}
          />
        )}
        <DomainMonitorHitDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.hitDialogShow}
          url={this.state.hitDialogUrl}
          onClose={this.hideHitDialog}
        />
        <UpdateProjectDialog
          show={this.state.updateProjectDialogShow}
          nbrOfHits={this.state.updateProjectDialogIds.length}
          submit={this.state.updateProjectDialogSubmit}
          errorMessage={this.state.updateProjectDialogErrorMessage}
          onSave={this.onUpdateProjectDialogSave}
          onCancel={this.onUpdateProjectDialogCancel}
        />
        <UpdateRiskDialog
          show={this.state.updateRiskDialogShow}
          value={riskValue}
          nbrOfHits={this.state.updateRiskDialogIds.length}
          onSave={this.onUpdateRiskDialogSave}
          onCancel={this.onUpdateRiskDialogCancel}
        />
        <UpdateCommentsDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.updateCommentDialogShow}
          comments={this.commentMap.get(this.state.updateCommentDialogId)}
          onSave={this.onUpdateCommentDialogSave}
          onCancel={this.onUpdateCommentDialogCancel}
          submit={this.state.updateCommentDialogSubmit}
          errorMessage={this.state.updateCommentDialogErrorMessage}
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.createOrderDialogShow}
          onClose={() => this.setState({ createOrderDialogShow: false })}
          step="OPERATION"
          operation="MANUAL-DOMAIN_INVESTIGATION"
          parameters={this.getCreateOrderParameters()}
        />
      </PageStyle>
    );
  }
}
