import React, { Component } from "react";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import fileDownload from "js-file-download";
import {
  ClearFix,
  Header,
  HeaderMeta,
  HeaderMetaLabel,
  HeaderMetaLastRow,
  HeaderMetaText,
  Organisation,
  PaddedContent,
  TitleWrapper,
  TruncatedTitle,
} from "../../common/theme/Theme";
import { FromByteArrayToBase64 } from "../../common/Utils";
import styled from "styled-components";
import Colors from "../../common/theme/Colors";
import { InternalLink } from "../../common/Link";
import { ActionButton, BlockingActionButton } from "../../common/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { darken } from "polished";
import UpdateCommentsDialog from "../../common/UpdateCommentsDialog";
import ChangeAccountDialog from "../../common/ChangeAccountDialog";
import ReadOnlyForm from "../../common/ReadOnlyForm";
import { IpRightImageDialog } from "./IpRightImageDialog";

const CommentsArea = styled.div`
  height: 90px;
  border-style: solid;
  border-color: ${Colors.border};
  border-width: thin;
  margin-top: 5px;
  padding-left: 6px;
  white-space: pre-wrap;
  text-align: left;
`;

const Comment = styled.div`
  height: 55px;
  overflow-y: scroll;
`;

const ActionButtons = styled.div`
  margin-top: 15px;
  display: block;
`;

const IconButtons = styled.div`
  position: absolute;
  bottom: 5px;
  right: 50%;
`;

const IconButton = styled(FontAwesomeIcon)`
  && {
    color: ${Colors.tertiary};
    font-size: 20px;
    cursor: pointer;
    &:hover {
      color: ${darken(0.5, Colors.tertiary)};
    }
  }
`;

class IpRightHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      ipRight: props.ipRight,
      changeAccountDialogShow: false,
      showUpdateCommentsDialog: false,
      showImageDialog: false,
      errorMessage: null,
      submit: null,
    };
  }

  componentDidMount() {
    this.reloadImage();
  }

  reload = () => {
    let url = this.state.ipRight.links.self;
    this.props.session.backendGet(url, (response) => {
      this.setState({
        ipRight: response,
      });
    });
  };

  reloadImage = () => {
    this.setState({ image: null });
    if (this.props.ipRight?.links.image) {
      this.props.session.backendGetFile(
        this.props.ipRight.links.image,
        (response) => {
          this.setState({ image: FromByteArrayToBase64(response) });
        },
      );
    }
  };

  showChangeAccountDialog = () => {
    this.setState({
      changeAccountDialogShow: true,
      errorMessage: null,
      submit: null,
    });
  };

  hideChangeAccountDialog = () => {
    this.setState({
      changeAccountDialogShow: false,
      errorMessage: null,
      submit: null,
    });
  };

  showUpdateCommentsDialog = () => {
    this.setState({
      showUpdateCommentsDialog: true,
      errorMessage: null,
      submit: null,
    });
  };

  hideUpdateCommentsDialog = () => {
    this.reload();
    this.setState({
      showUpdateCommentsDialog: false,
      submit: false,
      errorMessage: null,
    });
  };

  updateComments = (newComments) => {
    const message = "Comments updated successfully";
    const callback = () => {
      this.hideUpdateCommentsDialog();
      this.props.window.showSuccessFunc(message);
    };
    const errorFun = (msg) => {
      this.setState({ submit: false, errorMessage: msg });
    };
    this.setState({ submit: true });
    this.props.session.backendPut(
      this.state.ipRight.links.comments,
      {
        comments: newComments,
      },
      callback,
      errorFun,
    );
  };

  showImageDialog = () => {
    this.setState({
      showImageDialog: true,
    });
  };

  hideImageDialog = () => {
    this.setState({
      showImageDialog: false,
    });
  };

  export = () => {
    let url =
      this.props.session.getModules().portfolio.links.exportIpRights +
      "/" +
      this.props.ipRight.id;

    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, this.props.ipRight.catchWord + ".pdf");
        this.setState({ exported: Date.now() });
      },
      (error) => {
        this.props.window.showErrorFunc(error);
        this.setState({ exported: Date.now() });
      },
    );
  };

  getActionButtons = () => {
    let parts = [];
    let hasRoleOrderEdit = this.props.session.hasRole("ORDER_EDIT");

    if (this.state.ipRight) {
      parts.push(
        <ActionButton
          text={"Change Account"}
          icon={"edit"}
          prefix={"fas"}
          onClick={this.showChangeAccountDialog}
          disabled={!hasRoleOrderEdit}
          disabledTooltip={
            "Your user account does not have privileges to change account on the trademark, contact your client manager to activate this functionality"
          }
        />,
      );

      parts.push(
        <BlockingActionButton
          key="export"
          onClick={this.export}
          text={"Export"}
          icon={"cloud-download"}
          prefix={"fas"}
          tooltip="Exports the IP Right to a PDF file."
          reload={this.state.exported}
        />,
      );
    }

    return parts;
  };

  getMeta = (ipRight) => {
    let fields = [];

    fields.push(
      <Row key="projectMeta-row-1">
        <HeaderMeta xs={6}>
          <HeaderMetaLabel>Type</HeaderMetaLabel>
          <HeaderMetaText>{ipRight.productType}</HeaderMetaText>
        </HeaderMeta>
        <HeaderMeta xs={6}>
          <HeaderMetaLabel>Status</HeaderMetaLabel>
          <HeaderMetaText>{ipRight.legalProductState}</HeaderMetaText>
        </HeaderMeta>
      </Row>,
      <Row key="projectMeta-row-2">
        <HeaderMeta xs={6}>
          <HeaderMetaLabel>Office</HeaderMetaLabel>
          <HeaderMetaText>{ipRight.office}</HeaderMetaText>
        </HeaderMeta>
        <HeaderMeta xs={6}>
          <HeaderMetaLabel>Project</HeaderMetaLabel>
          <HeaderMetaText>{this.formatProject(ipRight)}</HeaderMetaText>
        </HeaderMeta>
      </Row>,
    );
    return <ClearFix>{fields}</ClearFix>;
  };

  formatProject = (ipRight) => {
    let projects = [];

    const hasProject = this.props.session.hasRole("PROJECT");

    ipRight.projects.forEach((project) => {
      projects.push(this.getProject(hasProject, project));
    });

    return projects;
  };

  getProject = (hasProjectRight, project) => {
    if (hasProjectRight) {
      return (
        <InternalLink
          to={"/project/projects/" + project.id}
          text={project.name}
          className="header-link"
        />
      );
    } else {
      let text = project.name;
      let tooltip = (
        <Tooltip style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
          Your user account does not have privileges to view the project.
          Contact your client manager to activate this functionality.
        </Tooltip>
      );
      return (
        <OverlayTrigger placement="bottom" overlay={tooltip}>
          <HeaderMetaText className="lower">{text}</HeaderMetaText>
        </OverlayTrigger>
      );
    }
  };

  getImage = () => {
    if (!this.state.image) {
      return;
    }
    return (
      <img
        key="image"
        src={"data:image/png;base64," + this.state.image}
        alt={""}
        style={{
          maxWidth: "100%",
          marginTop: "20px",
          maxHeight: "90px",
          cursor: "pointer",
        }}
        onClick={() => {
          this.showImageDialog();
        }}
      />
    );
  };

  getComments() {
    let fields = [];
    fields.push(
      <Row>
        <HeaderMetaLastRow xs={12}>
          <HeaderMetaLabel>Comments</HeaderMetaLabel>
          <CommentsArea>
            <Comment>{this.state.ipRight.comments}</Comment>
            {this.getCommentButton()}
          </CommentsArea>
        </HeaderMetaLastRow>
      </Row>,
    );
    return <ClearFix>{fields}</ClearFix>;
  }

  getCommentButton = () => {
    let buttons = [];
    const tooltip = (
      <Tooltip style={{ position: "fixed" }}>Edit comments</Tooltip>
    );
    buttons.push(
      <OverlayTrigger overlay={tooltip} placement={"bottom"}>
        <IconButton
          icon={["fas", "pen"]}
          onClick={this.showUpdateCommentsDialog}
        />
      </OverlayTrigger>,
    );
    return <IconButtons>{buttons}</IconButtons>;
  };

  render() {
    return (
      <div>
        <Header>
          <Container fluid>
            <Row>
              <Col xs={5}>
                <Row>
                  <TitleWrapper>
                    <TruncatedTitle
                      data={this.state.ipRight.catchWord}
                      isTitle={true}
                      breakAfter={50}
                    ></TruncatedTitle>
                    <Organisation>
                      {this.state.ipRight.organisation}
                    </Organisation>
                  </TitleWrapper>
                </Row>
                <Row>
                  <Col>
                    <ClearFix />
                    <ActionButtons>{this.getActionButtons()}</ActionButtons>
                  </Col>
                </Row>
              </Col>
              <Col xs={3}>
                <PaddedContent padding={"0px 20px 0 0"}>
                  {this.getMeta(this.state.ipRight)}
                </PaddedContent>
              </Col>
              <Col xs={2} style={{ textAlign: "center" }}>
                <PaddedContent padding={"0px 20px 0 0"}>
                  {this.getImage(this.state.ipRight)}
                </PaddedContent>
              </Col>
              <Col xs={2}>
                <PaddedContent padding={"0px 20px 0 0"}>
                  {this.getComments()}
                </PaddedContent>
              </Col>
            </Row>
          </Container>
        </Header>

        <UpdateCommentsDialog
          session={this.props.session}
          window={this.props.window}
          comments={this.state.ipRight.comments}
          show={this.state.showUpdateCommentsDialog}
          onCancel={this.hideUpdateCommentsDialog}
          onSave={this.updateComments}
          submit={this.state.submit}
          errorMessage={this.state.errorMessage}
        />

        {this.state.changeAccountDialogShow && (
          <ChangeAccountDialog
            session={this.props.session}
            window={this.props.window}
            show={this.state.changeAccountDialogShow}
            errorMessage={this.state.errorMessage}
            submit={this.state.submit}
            domainSubscriptionsUrl={this.state.ipRight.links.subscriptions}
            changeAccountUrl={this.state.ipRight.links.changeAccount}
            onClose={this.hideChangeAccountDialog}
          />
        )}

        <IpRightImageDialog
          ipRight={this.state.ipRight}
          show={this.state.showImageDialog}
          onClose={this.hideImageDialog}
          session={this.props.session}
        />
      </div>
    );
  }
}

export default IpRightHeader;
