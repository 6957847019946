import React, { Component } from "react";
import { DetailsDialog } from "../../common/Dialog";
import { Container } from "react-bootstrap";
import { FromByteArrayToBase64 } from "../../common/Utils";

export class IpRightImageDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
    };
  }

  componentDidMount() {
    this.reloadImage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.reloadImage();
    }
  }

  reloadImage = () => {
    this.setState({ image: null });
    if (this.props.ipRight?.links.image) {
      this.props.session.backendGetFile(
        this.props.ipRight.links.image,
        (response) => {
          this.setState({ image: FromByteArrayToBase64(response) });
        },
      );
    }
  };

  getImage = () => {
    if (!this.state.image) {
      return;
    }
    return (
      <img
        key="image"
        src={"data:image/png;base64," + this.state.image}
        alt={""}
        style={{ "max-width": "100%", "margin-top": "10px" }}
      />
    );
  };

  getBody = () => {
    let parts = [];
    if (this.props.ipRight) {
      parts.push(this.getImage(this.props.ipRight));
    }
    return <Container fluid={true}>{parts}</Container>;
  };

  render() {
    let title = "";
    if (this.props.ipRight) {
      title = this.props.ipRight.catchWord
        ? this.props.ipRight.catchWord
        : this.props.ipRight.productType;
    }

    return (
      <DetailsDialog
        show={this.props.show}
        title={title}
        body={this.getBody()}
        onClose={() => this.props.onClose()}
        width="lg"
        height="lg"
      />
    );
  }
}
