import React, { Component } from "react";
import { MainActionButton, TableButton } from "../../common/Buttons";
import { TableFrontend } from "../../common/Tables";
import ServerDialog from "./ServerDialog";

class ServerTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: null,
      showDialog: false,
      submit: null,
      errorMessage: null,
    };
    this.onCreate = this.onCreate.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.conf && prevProps.conf !== this.props.conf) {
      this.setState({ conf: this.props.conf });
    }
  }

  handleSubmit() {
    const url = this.props.zone.links.servers;
    const successFun = () => {
      this.closeDialog();
      const message = "Server successfully added!";
      this.props.window.showSuccessFunc(message);
      this.props.onChange();
    };
    const successEditFun = () => {
      this.closeDialog();
      const message = "Server successfully edited!";
      this.props.window.showSuccessFunc(message);
      this.props.onChange();
    };
    const errorFun = (msg) => {
      this.setState({ submit: null, errorMessage: msg });
    };
    if (this.state.conf.object.id) {
      this.setState({ submit: true });
      const url = this.state.conf.object.links.self;
      this.props.session.backendPut(
        url,
        this.state.conf.object,
        successEditFun,
        errorFun,
      );
    } else {
      this.setState({ submit: true });
      this.props.session.backendPost(
        url,
        this.state.conf.object,
        successFun,
        errorFun,
      );
    }
  }

  onCreate() {
    let confUrl = this.props.zone.links.serverConfiguration;
    this.props.session.backendGet(confUrl, (response) => {
      this.setState({ conf: response, showDialog: true });
    });
  }

  onEdit(row) {
    let confUrl = row.links.configuration;
    this.props.session.backendGet(confUrl, (response) => {
      this.setState({ conf: response, showDialog: true });
    });
  }

  onRemove(row) {
    const message = (
      <div>
        Are you sure you want to remove the server?
        <br />
        <pre>{row.ip}</pre>
      </div>
    );
    this.props.window.showConfirmFunc(message, () => {
      let url = row.links.self;
      this.props.session.backendDelete(url, (_) => {
        this.props.window.showSuccessFunc("Server successfully removed!");
        this.props.onChange();
      });
    });
  }

  closeDialog() {
    this.setState({ showDialog: false, submit: false, errorMessage: null });
  }

  render() {
    const columns = [
      {
        label: "IP",
        name: "ip",
        sortable: true,
        filterable: true,
      },
      {
        style: { width: "220px" },
        contentFunction: (r) =>
          !this.props.session.hasRole(
            "DNS_ADMIN_EDIT",
            "DNS_ADMIN_PUBLISH",
          ) ? null : (
            <div>
              <TableButton
                onClick={() => this.onEdit(r)}
                icon="edit"
                prefix={"fas"}
                text="Edit"
                className={"secondary"}
              />
              <TableButton
                onClick={() => this.onRemove(r)}
                icon="trash-alt"
                prefix={"fas"}
                text="Remove"
                className="remove"
              />
            </div>
          ),
      },
    ];

    const url = this.props.zone.links.servers;
    const actions = !this.props.session.hasRole(
      "DNS_ADMIN_EDIT",
      "DNS_ADMIN_PUBLISH",
    )
      ? null
      : [
          <MainActionButton
            key="add-server"
            onClick={this.onCreate}
            icon="plus"
            prefix={"fas"}
            text="Add Server"
          />,
        ];
    const { showDialog, conf, submit, errorMessage } = this.state;
    const { session, window } = this.props;

    return (
      <div>
        <ServerDialog
          session={session}
          showDialog={showDialog}
          closeDialog={this.closeDialog}
          conf={conf}
          createUrl={url}
          handleSubmit={this.handleSubmit}
          submit={submit}
          errorMessage={errorMessage}
        />
        <TableFrontend
          id="zone.server"
          session={session}
          window={window}
          actions={actions}
          filterable={true}
          columns={columns}
          sorting="ip"
          url={url}
          responseFunction={(o) => o.objects}
        />
      </div>
    );
  }
}

export default ServerTab;
