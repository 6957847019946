import React, { Component } from "react";
import { SwitchCheckbox } from "../common/theme/Theme";
import SearchableOrganisationFilter from "../common/SearchableOrganisationFilter";
import { TableBackend, TableFilter } from "../common/Tables";
import { InternalLink } from "../common/Link";
import styled from "styled-components";
import {
  FormatDateMonospace,
  FormatDateTime,
  FormatOrganisation,
} from "../common/Utils";
import ProjectTypeFilter from "../common/ProjectTypeFilter";
import Icon from "../common/theme/Icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Colors from "../common/theme/Colors";
import ProjectMessageTypeFilter from "../common/ProjectMessageTypeFilter";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const MessageColumn = styled.div`
  &.other-reply {
    color: ${Colors.warning};
  }
`;

class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        organisationId: "",
        projectTypes: this.getProjectTypeFilter(),
        freetext: "",
        onlyPending: this.getOnlyPendingParam(),
        onlyActive: true,
        reloadFlag: false,
      },
    };
  }

  getOnlyPendingParam = () => {
    const params = new URLSearchParams(this.props.location.search);
    if (params.get("onlyPending") != null) {
      return params.get("onlyPending");
    } else {
      return false;
    }
  };

  getProjectTypeFilter = () => {
    return this.props.session
      .getApplication()
      .projectTypes.filter((o) => o.typeCase === this.props.caseModule)
      .map((o) => o.name)
      .join(",");
  };

  componentDidMount() {
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: this.props.caseModule ? "Cases" : "Projects",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
  }

  getUrl = (sorting, expanding, parameters) => {
    let url;
    if (this.props.caseModule) {
      url = this.props.session.getModules().monitoring.links.cases;
    } else {
      url = this.props.session.getModules().project.links.projects;
    }
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&onlyActiveFilter=" + parameters.onlyActive;
    url += "&organisationIdFilter=" + parameters.organisationId;
    url += "&typeFilter=" + parameters.projectTypes;
    url += "&onlyPendingFilter=" + parameters.onlyPending;
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    return url;
  };

  getLastMessage = (message) => {
    if (message === null) {
      return;
    }
    let className = "";
    if (!message.fromCustomer && message.requireReply) {
      className = "other-reply";
    } else if (!message.fromCustomer && !message.requireReply) {
      className = "other-noreply";
    } else {
      className = "me";
    }
    let body;
    let messageDescription;
    if (message.messageType === "CUSTOMER_APPROVE") {
      body = <Icon prefix="far" name="thumbs-up" />;
      messageDescription = "Approved";
    } else if (message.messageType === "CUSTOMER_DECLINE") {
      body = <Icon prefix="far" name="thumbs-down" />;
      messageDescription = "Declined";
    } else {
      const rows = message.content.split("\n");
      messageDescription = message.content;
      if (rows.length > 1 || rows[0].length > 20) {
        body = rows[0].substring(0, 21) + "...";
      } else {
        body = rows[0];
      }
    }
    const tooltip = (
      <Tooltip style={{ position: "fixed", whiteSpace: "pre-line" }}>
        {message.createdBy +
          " " +
          FormatDateTime(message.created) +
          "\n" +
          messageDescription}
      </Tooltip>
    );
    const content = (
      <OverlayTrigger overlay={tooltip} placement={"bottom"}>
        <div>{body}</div>
      </OverlayTrigger>
    );
    return <MessageColumn className={className}>{content}</MessageColumn>;
  };

  render() {
    const filters = [
      <StyledDiv key="filters">
        {SwitchCheckbox(
          this.props.caseModule
            ? "Only display cases in progress"
            : "Only display projects in progress",
          this.state.parameters.onlyActive,
          (o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                onlyActive: o.target.checked,
              }),
            }),
        )}
        <SearchableOrganisationFilter
          key="organisationFilter"
          session={this.props.session}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                organisationId: o,
              }),
            })
          }
        />
        <ProjectTypeFilter
          session={this.props.session}
          caseModule={this.props.caseModule}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                projectTypes: o,
              }),
            })
          }
        />
        <ProjectMessageTypeFilter
          caseModule={this.props.caseModule}
          onlyPending={this.state.parameters.onlyPending}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                onlyPending: o,
              }),
            })
          }
        />
        <TableFilter
          value={this.state.parameters.freetext}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                freetext: o,
              }),
            })
          }
        />
      </StyledDiv>,
    ];

    const columns = [
      {
        label: "Name",
        name: "name",
        sortable: true,
        contentFunction: (r) => (
          <InternalLink
            to={
              this.props.caseModule
                ? "/monitoring/cases/" + r.id
                : "/project/projects/" + r.id
            }
            text={r.name}
            className="table-link"
          />
        ),
      },
      {
        label: "Organisation",
        name: "organisation",
        sortable: false,
        contentFunction: (r) =>
          FormatOrganisation(
            r.organisation,
            this.props.session.getApplication().organisationPrefix,
          ),
      },
      {
        label: this.props.caseModule ? "Case Number" : "Project Number",
        name: "projectNumber",
        sortable: true,
      },
      {
        label: "Type",
        name: "type",
      },
      {
        label: "Status",
        name: "status",
      },
      {
        label: "Last Message",
        name: "lastmessage",
        contentFunction: (r) => this.getLastMessage(r.lastMessage),
        sortable: false,
      },
      {
        label: "Last Activity",
        name: "lastActivity",
        sortable: true,
        filterable: true,
        contentFunction: (r) => FormatDateMonospace(r.lastActivity),
      },
    ];

    return (
      <TableBackend
        id={this.props.caseModule ? "case" : "project"}
        session={this.props.session}
        window={this.props.window}
        filters={filters}
        columns={columns}
        sorting="-lastActivity"
        parameters={this.state.parameters}
        urlFunction={this.getUrl}
      />
    );
  }
}

export default ProjectList;
