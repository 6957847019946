import React, { Component } from "react";
import { DropdownButton } from "./Buttons";
import { FilterSelector } from "./Selector";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

const InternalFilterStyle = styled.span`
  .filter {
    display: inline-block;
    margin-right: 20px;
    text-align: left;
  }
`;

const ExternalFilterStyle = styled.span`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
  }

  .level1 {
    padding-left: 40px;
  }

  .level2 {
    padding-left: 60px;
  }

  .level1::before,
  .level2::before {
    content: "\\2043\\202F\\202F";
  }

  .dropdown-menu {
    max-height: 400px;
    max-width: 450px;
    overflow: auto;
  }
`;

class InternalFilter extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let selectLabel = "Select " + this.props.type;

    return (
      <InternalFilterStyle>
        <span className="filter">
          <FilterSelector
            ignoreAccents={false}
            removeSelected={false}
            required={true}
            isClearable
            loadOptions={this.props.loadOptions}
            onChange={this.props.onChange}
            placeholder={selectLabel}
          />
        </span>
      </InternalFilterStyle>
    );
  }
}

class ExternalFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      options: [],
    };
  }

  fetchOptions() {
    let options = this.props.optionsCallback();
    this.setState({ options: options });
  }

  componentDidMount() {
    this.fetchOptions();
  }

  render() {
    let items = [];

    let allLabel = "All " + this.props.type;

    if (this.state.title === "") {
      this.state.title = allLabel;
    }

    items.push(
      <Dropdown.Item
        key="all"
        onClick={() => {
          this.props.onChange("", null);
          this.setState({ title: allLabel });
        }}
      >
        {allLabel}
      </Dropdown.Item>,
    );

    this.state.options.forEach((option) => {
      if (this.props.dropdownItemCreator) {
        items.push(this.props.dropdownItemCreator(option));
      } else {
        items.push(
          <Dropdown.Item
            key={option.description}
            className={"level" + option.level}
            onClick={() => {
              this.props.onChange(option.value);
              this.setState({ title: option.description });
            }}
          >
            {option.description}
          </Dropdown.Item>,
        );
      }
    });

    return (
      <ExternalFilterStyle>
        <DropdownButton
          title={this.state.title}
          style={{ verticalAlign: "top" }}
        >
          {items}
        </DropdownButton>
      </ExternalFilterStyle>
    );
  }
}

export class SearchableFilter extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.session.hasRole("SYS_ADMIN")) {
      return (
        <InternalFilter
          session={this.props.session}
          loadOptions={this.props.loadOptions}
          type={this.props.type}
          onChange={(o) => {
            this.props.onChange(o === null ? "" : o.value);
          }}
        />
      );
    } else {
      return (
        <ExternalFilter
          session={this.props.session}
          onChange={this.props.onChange}
          optionsCallback={this.props.staticOptions}
          type={this.props.type}
        />
      );
    }
  }
}
