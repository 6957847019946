import React, { Component } from "react";
import { TableFrontend } from "../common/Tables";
import styled from "styled-components";
import { MainActionButton, TableButton } from "../common/Buttons";
import fileDownload from "js-file-download";
import IdentityProviderDialog from "./IdentityProviderDialog";
import { FormatDateMonospace } from "../common/Utils";

const PageStyle = styled.div`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
  }
`;

export default class IdentityProviderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: null,
      identityProvider: null,
      errorMessage: null,
      parameters: {
        reloadFlag: false,
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Identity Providers",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);
  }

  getUrl = () => {
    return this.props.session.getModules().useradmin.links.identityProviders;
  };

  onCreate = () => {
    let confCreateUrl =
      this.props.session.getModules().useradmin.links.idpConfiguration;
    this.props.session.backendGet(confCreateUrl, (response) => {
      this.setState({
        showIdentityProviderDialog: true,
        availableRoles: response.availableRoles,
        identityProvider: response.object,
        create: true,
      });
    });
  };

  onEdit = (row) => {
    let confEditUrl = row.links.configuration;
    this.props.session.backendGet(confEditUrl, (response) => {
      this.setState({
        showIdentityProviderDialog: true,
        availableRoles: response.availableRoles,
        identityProvider: response.object,
        create: false,
      });
    });
  };

  onRemove = (row) => {
    const message = (
      <div>
        Are you sure you want to remove the identity provider: {row.id}?
      </div>
    );
    const errorFun = (msg) => {
      this.props.window.showErrorFunc(msg);
    };
    this.props.window.showConfirmFunc(message, () => {
      let url = row.links.self;
      this.props.session.backendDelete(
        url,
        () => {
          this.props.window.showSuccessFunc(
            "Identity provider successfully deleted",
          );
          this.reloadPage();
        },
        errorFun,
      );
    });
  };

  onExport = (row) => {
    const fileName = row.id + ".xml";
    this.props.session.backendGetFile(row.links.export, (r) =>
      fileDownload(r, fileName),
    );
  };

  onVerify = (row) => {
    const message =
      "To verify the configuration you first need to logout before triggering a service provider initiated login. Are you sure you want to logout?";
    this.props.window.showConfirmFunc(message, () => {
      this.props.session.logout(row.links.verify);
    });
  };

  closeDialog = () => {
    this.setState({
      identityProvider: null,
      showIdentityProviderDialog: false,
      errorMessage: null,
      submit: false,
    });
  };

  handleSubmit(identityProvider) {
    this.setState({ submit: true });

    const errorFun = (msg) => {
      this.setState({ submit: false, errorMessage: msg });
    };

    if (this.state.create) {
      const successFun = () => {
        this.closeDialog();
        this.reloadPage();
        const message = "Identity Provider successfully added.";
        this.props.window.showSuccessFunc(message);
      };

      const url =
        this.props.session.getModules().useradmin.links.identityProviders;
      this.props.session.backendPost(
        url,
        identityProvider,
        successFun,
        errorFun,
      );
    } else {
      const successFun = () => {
        this.closeDialog();
        this.reloadPage();
        const message = "Identity Provider successfully updated.";
        this.props.window.showSuccessFunc(message);
      };
      const url = identityProvider.links.self;
      this.props.session.backendPut(
        url,
        identityProvider,
        successFun,
        errorFun,
      );
    }
  }

  reloadPage() {
    this.setState({
      parameters: Object.assign({}, this.state.parameters, {
        reloadFlag: !this.state.parameters.reloadFlag,
      }),
    });
  }

  getButtons = (r) => {
    let buttons = [
      <TableButton
        key={"export-" + r.id}
        onClick={() => this.onExport(r)}
        icon="download"
        prefix={"fa"}
        text="Export"
        className="secondary"
        tooltip="Export the public service provider configuration."
      />,
      <TableButton
        key={"verify-" + r.id}
        onClick={() => this.onVerify(r)}
        icon="check-circle"
        prefix={"fa"}
        text="Verify"
        className="secondary"
        tooltip="Verify the service provider initiated login process."
      />,
    ];
    if (r.editable) {
      buttons.push(
        <TableButton
          key={"edit-" + r.id}
          onClick={() => this.onEdit(r)}
          icon="edit"
          prefix={"fas"}
          text="Edit"
          className={"secondary"}
        />,
      );
      buttons.push(
        <TableButton
          key={"remove-" + r.id}
          onClick={() => this.onRemove(r)}
          icon="trash-alt"
          prefix={"fas"}
          text="Remove"
          className="remove"
        />,
      );
    }
    return buttons;
  };

  render() {
    let actions = [
      <MainActionButton
        key="create"
        onClick={(r) => this.onCreate(r)}
        text="Create Identity Provider"
        icon="plus"
        prefix="fas"
        tooltip="Open the identity provider dialog and fill in details to create a new SAML integration."
      />,
    ];

    const columns = [
      {
        style: { width: "200px" },
        label: "Id",
        name: "id",
        sortable: true,
        filterable: true,
      },
      {
        label: "Organisation",
        name: "organisationDescription",
        sortable: true,
        filterable: true,
      },
      {
        label: "Certificate Expiry Date",
        name: "maxCertificateExpiryDate",
        sortable: true,
        contentFunction: (r) => FormatDateMonospace(r.maxCertificateExpiryDate),
      },
      {
        style: { width: "400px" },
        contentFunction: (r) => (
          <div style={{ textAlign: "right" }}>{this.getButtons(r)}</div>
        ),
      },
    ];
    return (
      <PageStyle>
        <TableFrontend
          id="identityprovider"
          ref={"table"}
          session={this.props.session}
          window={this.props.window}
          actions={actions}
          filterable={true}
          columns={columns}
          sorting="id"
          url={this.getUrl()}
          reload={this.state.parameters.reloadFlag}
        />
        {this.state.showIdentityProviderDialog && (
          <IdentityProviderDialog
            session={this.props.session}
            showDialog={this.state.showIdentityProviderDialog}
            closeDialog={this.closeDialog}
            identityProvider={this.state.identityProvider}
            create={this.state.create}
            window={window}
            handleSubmit={this.handleSubmit}
            submit={this.state.submit}
            availableRoles={this.state.availableRoles}
            errorMessage={this.state.errorMessage}
          />
        )}
      </PageStyle>
    );
  }
}
