import { darken } from "polished";
import React, { Component } from "react";
import styled from "styled-components";
import Colors from "./theme/Colors";
import Icon from "./theme/Icons";
import {
  DropdownButton as BoostrapDropdownButton,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

class ActionComponent extends Component {
  getTooltip = (tooltip) => {
    return (
      <Tooltip id="tooltip" className="position-absolute">
        {tooltip}
      </Tooltip>
    );
  };

  getButtonLabel = (hide, label) => {
    if (hide) {
      return <span className="d-none d-lg-inline">{label}</span>;
    }
    return label;
  };

  render() {
    if (!this.props.invisible) {
      const getIcon = (
        <Icon
          name={this.props.icon || this.props.text || "icon"}
          iconsize={this.props.iconsize}
          size={this.props.size}
          spin={this.props.spin}
          prefix={this.props.prefix}
        />
      );
      const ButtonIcon = () => {
        return this.props.icon ? getIcon : null;
      };

      let button = (
        <button
          {...this.props}
          type={this.props.type || "button"}
          size={this.props.size}
          className={this.props.className}
          onClick={this.props.onClick}
        >
          <ButtonIcon iconsize={this.props.iconsize} />
          {this.getButtonLabel(this.props.hidetext, this.props.text)}
        </button>
      );

      if (this.props.disabled) {
        button = (
          <ButtonDisabled
            style={this.props.style}
            hidetext={this.props.hidetext}
            text={this.props.text}
            type={"button"}
            className={
              this.props.className ? " disabled " + this.props.className : ""
            }
            icon={this.props.icon}
            iconsize={this.props.iconsize}
            size={this.props.size}
            spin={this.props.spin}
            prefix={this.props.prefix}
            tooltip={this.props.disabledTooltip}
          />
        );
      } else if (this.props.tooltip) {
        button = (
          <OverlayTrigger
            trigger={this.props.focus ? this.props.focus : ["hover", "focus"]}
            overlay={this.getTooltip(this.props.tooltip)}
            placement="bottom"
            delay={500}
            rootClose={true}
          >
            {button}
          </OverlayTrigger>
        );
      }
      return button;
    }
    return null;
  }
}

class SubmitFormButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledButton: false,
      buttonText: this.props.text,
    };
    this.setDisabledButton = this.setDisabledButton.bind(this);
  }

  setDisabledButton(e) {
    e.preventDefault();
    this.setState({
      disabledButton: true,
      buttonText: "Working...",
    });
  }

  render() {
    const { submit } = this.props;
    return (
      <MainActionButton
        type="submit"
        text={this.state.buttonText}
        icon={submit ? "spinner" : ""}
        spin={submit}
        disabled={submit}
      />
    );
  }
}

class BlockingMainActionButton extends Component {
  constructor(props) {
    super(props);
    this.state = { submit: false };
  }

  handleClick = (event) => {
    if (this.state.submit) {
      return;
    }
    event.preventDefault();

    this.setState({ submit: true });
    this.props.onClick();
  };

  componentDidUpdate(prevProps) {
    if (this.props.reload && prevProps.reload !== this.props.reload) {
      this.setState({ submit: false });
    } else if (
      this.props.taskRunning &&
      prevProps.taskRunning !== this.props.taskRunning
    ) {
      this.setState({ submit: true });
    }
  }

  render() {
    return (
      <MainActionButton
        type="submit"
        text={this.props.text}
        icon={
          this.state.submit && this.props.icon ? "spinner" : this.props.icon
        }
        size={this.props.size}
        prefix={this.props.prefix}
        spin={this.state.submit}
        className={this.props.className}
        tooltip={this.props.tooltip}
        disabled={this.props.disabled || this.state.submit}
        onClick={this.handleClick}
      />
    );
  }
}

const InfoButton = styled(ActionComponent)`
  && {
    width: ${(props) => (props.size ? props.size : "25px")};
    height: ${(props) => (props.size ? props.size : "25px")};
    border-radius: ${(props) => (props.size ? props.size : "25px")};
    background: ${(props) =>
      props.background ? props.background : Colors.actionButtonBackground};
    color: ${Colors.actionButton};
    border: none;
    padding: ${(props) => (props.padding ? props.padding : "0")};
    margin: ${(props) => (props.margin ? props.margin : "0")};
    box-shadow: ${(props) =>
      props.shadow ? "0px 2px 12px 3px rgba(Colors.black,0.15)" : "0"};
    outline-color: "transparent";
    outline-style: none;

    svg {
      font-size: ${(props) => (props.iconsize + "px" ? props.iconsize : null)};
    }
  }
`;

const ButtonBase = styled(ActionComponent)`
  && {
    transition: all 500ms ease 0s;
    display: inline-block;
    position: relative;
    border-radius: 3px;
    padding: ${(props) => props.padding || "8px 12px"};
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    margin-right: 10px;
    margin-bottom: 5px;
    border: 1px solid transparent;
    max-height: 38px;
    height: auto;

    &:hover,
    &:focus {
      text-decoration: none;
      cursor: pointer;
      border-color: "transparent";
    }

    &:last-child {
      margin-right: 0;
    }

    .fa {
      margin-right: 5px;
      font-size: 16px;
    }

    svg {
      position: relative;
      top: 1px;
      margin-right: 8px;
      font-size: 16px;
    }

    &.small {
      padding: 5px 10px;
      font-size: 10px;

      .fa {
        font-size: 13px;
        top: 2px;
        margin-right: 3px;
      }
    }

    &.delete {
      background: ${Colors.deleteButtonBackground};
      color: ${Colors.deleteButton};

      &:hover {
        background: ${darken(0.15, Colors.deleteButtonBackground)};
      }
    }

    &.main {
      background: ${Colors.mainActionButtonBackground};
      color: ${Colors.mainActionButton};

      &:hover {
        background: ${darken(0.15, Colors.mainActionButtonBackground)};
      }
    }

    &.disabled {
      background: ${Colors.disabledButtonBackground};

      &:hover {
        cursor: default;
        background: darkgrey;
      }
    }
  }
`;

const TableButton = styled(ButtonBase)`
  && {
    font-size: 10px;
    font-weight: normal;
    padding: 4px 8px;
    margin-bottom: 0;
    border: 1px solid transparent;

    &.remove {
      background: ${Colors.deleteButtonBackground};
      color: ${Colors.deleteButton};

      &:hover {
        background: ${darken(0.15, Colors.deleteButtonBackground)};
      }
    }

    &.primary {
      background: ${Colors.actionButtonBackground};
      color: ${Colors.actionButton};

      &:hover {
        background: ${darken(0.15, Colors.actionButtonBackground)};
      }
    }

    &.secondary {
      background: ${Colors.navigationButtonBackground};
      color: ${Colors.navigationButton};

      &:hover {
        background: ${darken(0.15, Colors.navigationButtonBackground)};
      }
    }

    &.disabled {
      background: darkgrey;

      &:hover {
        cursor: pointer;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    svg {
      font-size: 12px;
      top: 1px;
      margin-right: 5px;
    }
  }
`;

const ActionButton = styled(ButtonBase)`
  && {
    background: ${Colors.actionButtonBackground};
    color: ${Colors.actionButton};

    &:hover {
      background: ${darken(0.15, Colors.actionButtonBackground)};
      color: ${Colors.actionButton};
    }
  }
`;

const LogoutButton = styled(ButtonBase)`
  && {
    background: ${Colors.white};
    color: ${Colors.tertiary};
    border: 1px solid lightgray;

    &:hover {
      background: ${darken(0.15, Colors.white)};
      color: ${Colors.tertiary};
    }
  }
`;

const MainActionButton = styled(ActionButton)`
  &&{
    background: ${Colors.mainActionButtonBackground};
    color: ${Colors.mainActionButton};
    
    &:hover {
      background: ${darken(0.15, Colors.mainActionButtonBackground)};
      color: ${Colors.mainActionButton};
    }
`;

const BlockingActionButton = styled(BlockingMainActionButton)`
  && {
    background: ${Colors.actionButtonBackground};
    color: ${Colors.actionButton};

    &:disabled {
      background: darkgrey;

      &:hover {
        cursor: pointer;
        background: darkgrey;
      }

      pointer-events: none;
    }

    &:hover:not(disabled) {
      background: ${darken(0.15, Colors.actionButtonBackground)};
      color: ${Colors.actionButton};
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

const TableNavigationButton = styled(ButtonBase)`
   && {		
	   background-color: #fff;
       border-color: #ccc;
       color: #333;
       font-size: 14px;
       font-weight: 400;
       text-transform:inherit;
       padding: 6px 12px;

       &:active {
	      background-color: #fff;
          border-color: #ccc;
       }
    } 
  }
`;

const DropdownButton = styled(BoostrapDropdownButton)`
  && {
    display: inline-block;

    &.btn {
      background-color: #fff;
      border-color: #ccc;
      color: #333;
      font-size: 14px;
      font-weight: 400;

      &:active {
        background-color: #fff;
        border-color: #ccc;
      }
    }
  }

  .dropdown-toggle {
    background-color: ${Colors.tableNavigationButtonBackground};
    color: ${Colors.tableNavigationButton};
    border-color: ${Colors.border};
    font-size: 14px;
    font-weight: 400;
    height: 34px;

    :active {
      background-color: ${Colors.tableNavigationButtonBackground};
      border-color: ${Colors.border};
    }
  }

  .show.dropdown-toggle {
    background: ${Colors.tableNavigationButtonBackground};
    color: ${Colors.tableNavigationButton};
    border-color: ${Colors.border};
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  }
`;

const DeleteButton = styled(ButtonBase)`
  && {
    background: ${Colors.deleteButtonBackground};
    color: ${Colors.deleteButton};

    &:hover {
      background: ${darken(0.15, Colors.deleteButtonBackground)};
      color: ${Colors.deleteButton};
    }
  }
`;

const ButtonDisabled = styled(ButtonBase)`
  && {
    background: ${Colors.disabledButtonBackground};
    color: ${Colors.disabledButton};

    &:hover {
      cursor: default;
      background: ${Colors.disabledButtonBackground};
    }
  }
`;

const NavigationButton = styled(ButtonBase)`
  && {
    background: ${Colors.navigationButtonBackground};
    color: ${Colors.navigationButton};

    &:hover {
      background: ${darken(0.15, Colors.navigationButtonBackground)};
      color: ${Colors.navigationButton};
    }
  }
`;

export {
  ActionButton,
  MainActionButton,
  BlockingActionButton,
  BlockingMainActionButton,
  DeleteButton,
  InfoButton,
  NavigationButton,
  SubmitFormButton,
  TableButton,
  TableNavigationButton,
  DropdownButton,
  LogoutButton,
};
