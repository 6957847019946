import React, { Component } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { withRouter } from "react-router";
import PortfolioPage from "./PortfolioPage";
import IpRightPage from "./ipright/IpRightPage";

class Portfolio extends Component {
  render() {
    return (
      <Switch>
        <Route
          path="/portfolio/ipRights/:ipRightId"
          render={(props) => (
            <IpRightPage
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          path="/portfolio/:page?"
          render={(props) => (
            <PortfolioPage
              history={this.props.history}
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(Portfolio);
