import React, { Component } from "react";
import { TableButton } from "../../common/Buttons";
import { TableFrontend } from "../../common/Tables";
import { FormatDateTime, FormatDateTimeMonospace } from "../../common/Utils";
import { FormatChangeSummary } from "../util/ChangeSummaryUtil";
import ZoneComparisonDialog from "../common/ZoneComparisonDialog";

class HistoryTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showZoneComparison: false,
      zoneComparisonUrl: null,
    };
    this.restoreZone = this.restoreZone.bind(this);
    this.showZoneComparison = this.showZoneComparison.bind(this);
  }

  restoreZone(publishedZoneId) {
    const message = "Are you sure want to restore this Zone?";
    this.props.window.showConfirmFunc(message, () => {
      let url =
        this.props.zone.links.self +
        "/publications/" +
        publishedZoneId +
        "/restore";
      this.props.session.backendPost(url, null, (_) => {
        this.props.onChange();
        const message = "Zone was successfully restored";
        this.props.window.showSuccessFunc(message);
      });
    });
  }

  showZoneComparison(publishedZoneId) {
    this.setState({
      showZoneComparison: true,
      zoneComparisonUrl:
        this.props.zone.links.self + "/publications/" + publishedZoneId,
    });
  }

  closeZoneComparison = () => {
    this.setState({ showZoneComparison: false });
  };

  render() {
    const buttonView = (r) => (
      <div>
        <TableButton
          onClick={() => this.showZoneComparison(r.id)}
          icon="eye"
          prefix={"fas"}
          text="View"
          className={"secondary"}
        />
      </div>
    );

    const buttonEdit = (r) => (
      <div>
        <TableButton
          onClick={() => this.showZoneComparison(r.id)}
          icon="eye"
          prefix={"fas"}
          text="View"
          className={"secondary"}
        />
        <TableButton
          onClick={() => this.restoreZone(r.id)}
          icon="undo"
          prefix={"fas"}
          text="Restore"
          className={"primary"}
        />
      </div>
    );

    const columns = [
      {
        label: "Created",
        name: "createdAt",
        sortable: true,
        filterable: true,
        contentFunction: (r) => FormatDateTimeMonospace(r.createdAt),
        filterFunction: (r) => FormatDateTime(r.createdAt),
      },
      {
        label: "User",
        name: "createdBy",
        sortable: true,
        filterable: true,
      },
      {
        label: "Change Summary",
        name: "changeSummaryCounters",
        sortable: true,
        filterable: true,
        sortFunction: (r) => FormatChangeSummary(r.changeSummaryCounters),
        contentFunction: (r) => FormatChangeSummary(r.changeSummaryCounters),
        filterFunction: (r) =>
          FormatChangeSummary(r.changeSummaryCounters) + r.changeSummaryValues,
      },
      {
        style: { width: "220px" },
        contentFunction: !this.props.session.hasRole(
          "DNS_ADMIN_EDIT",
          "DNS_ADMIN_PUBLISH",
        )
          ? buttonView
          : buttonEdit,
      },
    ];

    const { zoneComparisonUrl, showZoneComparison } = this.state;
    const { session, window } = this.props;
    const url = this.props.zone.links.publications;
    return (
      <div>
        <ZoneComparisonDialog
          session={session}
          url={zoneComparisonUrl}
          show={showZoneComparison}
          title="Published Zone"
          onClose={this.closeZoneComparison}
        />
        <TableFrontend
          id="zone.history"
          session={session}
          window={window}
          columns={columns}
          sorting="-createdAt"
          filterable={true}
          url={url}
          responseFunction={(o) => o.objects}
        />
      </div>
    );
  }
}

export default HistoryTab;
